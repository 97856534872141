import React from 'react';
import { Link } from "react-router-dom";

import '../index.css';
import './css/Footer.css';
import Logo from '../assets/images/logo.png';
import CalculatorIcon from "../assets/svg/calculator.svg";

const Footer = () => {
  return (

      <div className="bakground-container">
        <div className="footer-container">
          <div className="footer-row">
            <div className='tandem-copyright'>
              <img src={Logo} alt="TANDEM" />
              <p className='copyright-info'>Tandem Metals - Copyright 2023<br/>All rights reserved.</p>
            </div>
            <div className='buttons-and-links'>
              <div className='footer-buttons'>
                <Link to="/quote" className='button-link'>
                  <p>Get&nbsp;a&nbsp;quote</p>
                </Link>
                <Link to="/" className='button-link button-link-dark' state={{ goToPiw: true }}>
                  <img src={CalculatorIcon} alt="Calc" />
                  <p>PIW&nbsp;Calculator</p> 
                </Link>
              </div>
              <div className='footer-links'>
                <Link to="/services" className='footer-links-text'>Services</Link>
                <Link to="/products" className='footer-links-text'>Products</Link>
                <Link to="/" className='footer-links-text' state={{ goToPiw: true }}>Technical Data</Link>
                <Link to="/about" className='footer-links-text'>About us</Link>
                <Link to="/contact" className='footer-links-text'>Contact us</Link>
              </div>
            </div>
            <div className="buttons2">
              <div className='footer-buttons2'>
                <Link to="/services" className='button-link'>
                  <div className="navbar-btn-blue">
                    <p className='button-link-text'>View&nbsp;our&nbsp;Services</p>
                  </div>
                </Link>
                <Link to="/contact" className='button-link button-link-dark'>
                  <div className="">
                    <p className='button-link-text'>Contact&nbsp;us</p> 
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="filler"></div>
      </div>
      


  )
}

export default Footer