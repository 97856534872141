import React from 'react';
import { Link } from 'react-router-dom';

const CutToLenght = () => {
  return (
    <div className="single-service-background">
      <div className="single-service-container main-top-padding">
        <h1 className='main-service-heading'>Cut To Length</h1>
        <div className="two-paragraphs-row service-top-margin service-bottom-margin">
          <div className='make-50-width'>
            <p className='main-paragraph-styling paragraph-right-margin service-bottom-margin'>Annealing is a heat treatment process which alters the metallurgical structure of a material to change its mechanical or electrical properties. Typically, this process is used to decrease metal crack sensitivity of aged material that needs to be returned to a weldable state. Solution Annealing will affect secondary carbides precipitated during extended high temperature service by increasing ambient temperature ductility and fracture toughness. This process involves air quenching post heat application.</p>
            <div className="service-buttons-container service-bottom-margin-on-s-screen">
              <Link to="/project" className="services-btn-bue">View&nbsp;our&nbsp;Products</Link>
              <Link to="/project" className="services-btn-white">Contact&nbsp;us</Link>
            </div>
          </div>
          <div className="two-paragraphs-in-column make-50-width">
            <div className="cut-to-table-container">
              <div className="cut-to-columns">
                <div className="cut-to-rows">
                  <div className="cut-to-first-column">
                    <p>Gauges:</p>
                  </div>
                  <div className="cut-to-second-column">
                    <p>.030” - .250”</p>
                  </div>
                </div>
                <div className="cut-to-rows">
                  <div className="cut-to-first-column">
                    <p>Strip Widths:</p>
                  </div>
                  <div className="cut-to-second-column">
                    <p>.375” - 12.5”</p>
                  </div>
                </div>
                <div className="cut-to-rows">
                  <div className="cut-to-first-column">
                    <p>Lengths:</p>
                  </div>
                  <div className="cut-to-second-column">
                    <p>4" - 20 feet</p>
                  </div>
                </div>
                <div className="cut-to-rows">
                  <div className="cut-to-first-column">
                    <p>Length Tolerance:</p>
                  </div>
                  <div className="cut-to-second-column">
                    <p>+/- .010”</p>
                  </div>
                </div>
                <div className="cut-to-rows">
                  <div className="cut-to-first-column">
                    <p>Edges:</p>
                  </div>
                  <div className="cut-to-second-column">
                    <p>Slit edge, Deburred, Square, Round, and Specialty Edges</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CutToLenght 