import React from 'react'
import QuoteForm from '../components/QuoteForm';
import Footer from '../components/Footer';

const Quote = () => {
  return (
    <>
      <QuoteForm />
      <Footer />
    </>
  )
}

export default Quote