import React, { useState, useEffect } from 'react';
import './css/contact.css';
import testPdf from '../assets/certificates/iso-certificate.pdf';

const ContactUs = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  //HANDLE FORM SUBMIT
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    const name = data['Name'];
    const email = data['Email'];
    const telephone = data['Telephone'];
    const company = data['Company'];
    const message = data['Message'];
    const config = {
      SecureToken : 'd27318ef-9c8a-4647-a92b-4e7204bfa6a2',
      To : 'info@tandemmetals.com',
      From : `info@tandemmetals.com`,
      Subject : "Contact",
      Body :`
        <p>Name: ${name}</p>
        <p>Email: ${email}</p>
        <p>Telephone: ${telephone}</p>
        <p>Company: ${company}</p>
        <p>Message: ${message}</p>
      `,
    }
    if(window.Email) {
      window.Email.send(config).then(() => alert("Email sent successfully"));
    }else{
      console.log("Email not sent");
    }
  };

  const [status, setStatus] = useState("Submit");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
    <div className="contact-page-container-bg">
      <div className="contact-page-container contact-bottom-178">
        <div className="contact-page-layout-container">
          <div className='make-50-width contact-right-margin-to-form margin-bottom-from-1100'>
            <h1 className='contact-heading contact-bottom-32'>Contact Us!</h1>
            <p className='contact-paragraph-styling contact-bottom-97'>We'd love to hear from you! Please don't hesitate to contact us if you have any questions or inquiries about our products and services.</p>
            <div className="contact-left-border">
              <p className='contact-paragraph-styling contact-bottom-mini'><span className='contact-blue-text'>Email:</span>&nbsp;&nbsp;info@tandemmetals.com</p>
              <p className='contact-paragraph-styling contact-bottom-mini'><span className='contact-blue-text'>Phone:</span>&nbsp;&nbsp;708-235- 8912</p>
              <p className='contact-paragraph-styling contact-bottom-mini'><span className='contact-blue-text'>Business hours:</span>&nbsp;&nbsp;8:00 AM - 5:00 PM</p>
            </div>
          </div>
          <div className='make-50-width'>
            <form className='email-form' onSubmit={handleSubmit}>
              <div className='form-1col form-2col-from-750'>
                <input type="text" name="Name" className='form-input-style' value={name}  onChange={(event) => setName(event.target.value)} placeholder='Your Name' />
              </div>
              <div className='form-1col form-2col-from-750'>
                <input type="email" name="Email" className='form-input-style' value={email}  onChange={(event) => setEmail(event.target.value)} placeholder='Your Email'/>
              </div>
              <div className='form-1col form-2col-from-750'>
                <input type="text" name="Telephone" className='form-input-style' value={telephone}  onChange={(event) => setTelephone(event.target.value)}  placeholder='Telephone'/>
              </div>
              <div className='form-1col form-2col-from-750'>
                <input type="text" name="Company" className='form-input-style' value={company}  onChange={(event) => setCompany(event.target.value)} placeholder='Company'/>
              </div>
              <div className='form-2col form-2col-from-750'>
                <textarea className='textarea-style' name="Message" value={message}  onChange={(event) => setMessage(event.target.value)} placeholder='Enter your message here'/>
              </div>
              <button type="submit" value="Send Email" className='navbar-btn-blue-msg-send form-2col-from-750'>Send&nbsp;a&nbsp;message</button>
              {/* <input type="submit" value="Send Email" name="" id="" /> */}
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default ContactUs