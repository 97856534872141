import React, { useEffect } from 'react';
import './css/About.css';

// import ceo1 from '../assets/images/ceo1.png';
// import ceo2 from '../assets/images/ceo2.png';
import TandemBuilding from '../assets/images/tandem-building.png';

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='about-bg-container'>
        <div className='about-main-container'>
          <div className="about-main-flex">
            <div className='about-50-width'>
              <h1 className='about-heading'>About us</h1>
                <p className='about-paragraph about-paragraph-margin'>Tandem Metals, INC. was founded in 1993 by Drago and Robin Kragulj. Our initial business was custom metal slitting of customers’ material. After several of our major customers requested that we also supply the metal for their orders, we soon were buying more equipment and base stock to meet our customers’ requirements. This required us to purchase 40,000 square feet of industrial space in Wheeling Illinois, which served us for five years. In 2003 we were able to purchase a larger facility of 130,000 square feet needed to accommodate additional machinery and inventory. Our main product line was supplying the metal for shielding used in cellular phones. From there, we added more products, such as stainless steel and other cold rolled products. Our philosophy in the metal business continues to be customer driven.</p>
                <p className='about-paragraph about-paragraph-margin'>With ever increasing pressures on manufacturers to decrease inventory costs, our customers have continued to purchase smaller quantities. This has presented challenges on how to remain competitive while supplying smaller orders.</p>
                <p className='about-paragraph first-part-padding-bottom'>Tandem then became a cold reducer of strip metal. Presently, a customer can purchase 100 lbs of a particular thickness at a reasonable price due to our ability in application of our inventory more diversely. Most rerollers have a 10,000 lb. minimum order. Our customers will continue to dictate the evolution of our business. In an ever changing global economy, businesses must stay ahead of the curve, and we at Tandem Metals will do “whatever it takes” to keep our customers competitive in the local and global arena.</p>
            </div>
            <div className='about-50-width-for-images'>
              {/* <div className="about-image-flex">
                <div className="about-image-container image-ceo-1">
                  <img src={ceo1} alt="" className='image-ceo'/>
                  <h3 className='ceo-names'>Drago Kragulj</h3>
                  <h4 className='ceo-positions'>(Founder & CEO)</h4>
                </div>
                <div className="about-image-container image-ceo-2">
                  <img src={ceo1} alt="" className='image-ceo '/>
                  <h3 className='ceo-names'>Robin Kragulj</h3>
                  <h4 className='ceo-positions'>(Founder & CEO)</h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="about-second-bg-container">
        <div className="about-main-container">
          <div className="about-second-flex second-part-padding">
            <div className="building-image-container">
              <img src={TandemBuilding} alt="" className='building-image'/>
            </div>
            <div className='missions-container'>
              <h2 className='mission-heading'>Our vision</h2>
              <p className='paragraph-heading'>To stand out in the metal services industry due to the consistency of our high quality workmanship, dependable service, and our dedication to customer satisfaction.</p>
              <h2 className='mission-heading'>Our mission</h2>
              <p className='paragraph-heading'>Tandem metals will do “whatever it takes” to keep its customers competitive in the global arena.</p>
              <h2 className='mission-heading'>Core values</h2>
              <p className='paragraph-heading'>Reducing cost, Improving quality, Saving time and Minimizing risk.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs