import React from 'react'

const Rolling = () => {
  return (
    <div className="single-service-background main-top-padding">
      <div className="single-service-container">
        <h1 className='main-service-heading'>Rolling</h1>
        <p className='main-paragraph-styling make-50-width bold-text service-top-margin'>Cold Rolling And Reduction</p>
        <div className="two-paragraphs-row service-top-margin service-bottom-margin">
          <p className='main-paragraph-styling make-50-width paragraph-right-margin service-bottom-margin-on-s-screen'>We have the capacity to do gauge correction, temper rolling and surface finish improvement. This is possible in carbon steel, stainless steel, red metals and any other special alloy. This can also be done a on a toll basis to bring your narrow material down to desired gauge or up to desired hardness. While on our edging line we can also correct for camber and condition the edges of your material.</p>
          <p className='main-paragraph-styling make-50-width bold-text'>Tandem Metals offers full blown reversing mill rolling of ferrous and non-ferrous strip stock. Our experienced staff has been trained to handle any request you have.</p>
        </div>
        <div className='service-table-heading'>
          <h3>Our slitting capabilities</h3>
        </div>
        <div className="services-table-grid">
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Gauge Range:</p>
            </div>
            <div className="second-in-pair">
              <p>0.001” - 0.250”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Maximum Coil Width</p>
            </div>
            <div className="second-in-pair">
              <p>36”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Maximum Coil OD:</p>
            </div>
            <div className="second-in-pair">
              <p>52”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Maximum Coil Weight:</p>
            </div>
            <div className="second-in-pair">
              <p>15,000 lbs</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Uncoiler ID:</p>
            </div>
            <div className="second-in-pair">
              <p>6” to 20”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Recoiler ID:</p>
            </div>
            <div className="second-in-pair">
              <p>6” and 20”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Maximum Rolling Width:</p>
            </div>
            <div className="second-in-pair">
              <p>8.625”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Minimum Rolling Width:</p>
            </div>
            <div className="second-in-pair">
              <p>.625”</p>
            </div>
          </div>
        </div>
        <p className='main-paragraph-styling service-top-margin service-bottom-margin make-50-width'>Quality control procedures assure that the following meet our customer’s specifications:</p>
        <div className="services-boxes-container">
          <div className="services-box">
            <p>Cold Rolled</p>
          </div>
          <div className="services-box">
            <p>Low carbon</p>
          </div>
          <div className="services-box">
            <p>High carbon</p>
          </div>
          <div className="services-box">
            <p>Annealed</p>
          </div>
          <div className="services-box">
            <p>Pre-tempered spring steels</p>
          </div>
          <div className="services-box">
            <p>Coated</p>
          </div>
          <div className="services-box">
            <p>Stainless</p>
          </div>
          <div className="services-box">
            <p>Brass</p>
          </div>
          <div className="services-box">
            <p>Copper</p>
          </div>
          <div className="services-box">
            <p>Bronze</p>
          </div>
          <div className="services-box">
            <p>Titanium</p>
          </div>
          <div className="services-box">
            <p>Phosphor Bronze</p>
          </div>
          <div className="services-box">
            <p>Hastelloy</p>
          </div>
          <div className="services-box">
            <p>Inconel</p>
          </div>
          <div className="services-box">
            <p>Nickel</p>
          </div>
          <div className="services-box">
            <p>Monel</p>
          </div>
        </div>
      </div>
  </div>
  )
}

export default Rolling