import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './css/LandingPage.css'
import '../index.css'


import IntroAnimation from '../assets/animations/sheets-transparent.webm'
import IntroAnimationApple from '../assets/animations/sheets-tandem.mp4'

import Slitting from '../assets/images/Slitting.jpg'
import Rolling from '../assets/images/Rolling.jpg'
import CutToLenght from '../assets/images/Cut-to-Lenght.jpg'
import AnalyticalLaboratory from '../assets/images/Analytical-laboratory.jpg'
import Annealing from '../assets/images/Annealing.jpg'
import Edging from '../assets/images/Edging.jpg'
import TollProcessing from '../assets/images/Toll-Processing.jpg'
import Packaging from '../assets/images/Packaging.jpg'

import stainlessSteel from '../assets/images/Products/Stainless-Steel.png'
import CarbonSteel from '../assets/images/Products/Carbon-Steel.png'
import CoatedProducts from '../assets/images/Products/Coated-Products.png'
import AlloySteel from '../assets/images/Products/Alloy-Steel.png'
import RedMetals from '../assets/images/Products/Red-Metals.png'
import Aluminium from '../assets/images/Products/Aluminium.png'
import HSLA from '../assets/images/Products/HSLA.png'
import HRPO from '../assets/images/Products/HRPO.png'

import UpArrow from '../assets/svg/up-arrow.svg'
import RightTriangle from '../assets/svg/right-facing-triangle.svg'

import IsoCertificate from '../assets/images/certificates/iso-certificate.png'
import PrecisionMetalformingAssociation from '../assets/images/certificates/pma.png'
import DownloadArrow from '../assets/svg/download-arrow.svg'
import PdfIsoCertificate from '../assets/certificates/iso-certificate.pdf'
import PdfProofOfCertificate from '../assets/certificates/proof-of-certificate.pdf'




import CalculatorIcon from "../assets/svg/calculator.svg";
import PhoneIcon from "../assets/svg/phone.svg";
import LogoLight from "../assets/images/Logo-Light.png";
import HamburgerClose from "../assets/svg/Hamburger-Close.svg";
import HamburgerLight from "../assets/svg/Hamburger-light.svg";


import './css/Navbar.css';




const LandingPage = () => {
  // Disable transitions on browser resize
  const [disableTransition, setDisableTransition] = useState(false)
  const [disableTransition2, setDisableTransition2] = useState(false)

  useEffect(() => {
    let resizeTimeout

    const handleResize = () => {
      setDisableTransition(true)
      setDisableTransition2(true)

      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        setDisableTransition(false)
      }, 200) // Adjust the timeout value to match the transition duration

      setTimeout(() => {
        setDisableTransition2(false)
      }, 500) // Adjust the timeout value for the duration of no-transition2 class
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(resizeTimeout)
    }
  }, [])

  // End

  const topRef = useRef(null)
  const refTwo = useRef(null)
  const location = useLocation()
  const goToPiw = location.state?.goToPiw

  useEffect(() => {
    const targetRef = goToPiw ? refTwo.current : topRef.current

    if (targetRef) {
      const navbarheight = document.getElementById('navbar-height').offsetHeight
      const element = targetRef.offsetTop
      let scrollAmount = element - navbarheight + 10

      window.scrollTo({
        top: scrollAmount,
        behavior: 'instant',
      })
    }
  }, [goToPiw])

  const [collapse, setCollapse] = useState(false)
  const [buttonText, setButtonText] = useState('Show more')
  const [pickedCalculator, setPickedCalculator] = useState('diameter')

  const scrollToDiv = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    })
  }

  function buttonHandler() {
    setCollapse((prev) => !prev)
    if (buttonText === 'Show more') {
      setButtonText('Show less')
    } else {
      setButtonText('Show more')
    }
  }

  function CalculatorPickHandler(pickedCalculator) {
    if (pickedCalculator === 'diameter') {
      setPickedCalculator('diameter')
    } else {
      setPickedCalculator('weight')
    }
  }

  const handleClickByDiameter = () => {
    let a = document.getElementById('calc1-input-id').value
    let o = document.getElementById('calc1-input-od').value
    let n = document.getElementById('calc1-input-width').value
    let r = document.getElementById('calc1-input-thickness').value

    // Check Input
    let anyErrors = false
    if (a === '' || isNaN(a) || a === null || a < 16 || a > 36) {
      setCalc1ErrorId('Error* Invalid input.')
      anyErrors = true
    }
    if (o === '' || isNaN(o) || o === null || o < a || o < 16 || o > 84) {
      setCalc1ErrorOd('Error* Invalid input.')
      anyErrors = true
    }
    if (n === '' || isNaN(n) || n === null || n < 0.25 || n > 84) {
      setCalc1ErrorWidth('Error* Invalid input.')
      anyErrors = true
    }
    if (r === '' || isNaN(r) || r === null || r < 0.005 || r > 0.65) {
      setCalc1ErrorThickness('Error* Invalid input.')
      anyErrors = true
    }
    if (anyErrors) {
      return
    }

    let s =
      (Math.PI * Math.pow(o / 2, 2) * 0.2833 -
        Math.PI * Math.pow(a / 2, 2) * 0.2833) *
      n
    let u = s / (n * r * 0.2833 * 12)
    let c = s / n
    let h = (o - a) / 2

    let toConvertToString = [c, s, u, a, o, h]
    let outputString = []

    for (let i = 0; i < 6; i++) {
      if (Math.floor(toConvertToString[i]) === toConvertToString[i]) {
        outputString.push('' + toConvertToString[i])
      } else {
        const convertedNumber = parseFloat(toConvertToString[i])
        outputString.push('' + convertedNumber.toFixed(1))
      }
    }
    document.getElementById('calc1-output-piw').value = outputString[0]
    document.getElementById('calc1-output-weight').value = outputString[1]
    document.getElementById('calc1-output-lenght').value = outputString[2]
    document.getElementById('calc1-output-id').value = outputString[3]
    document.getElementById('calc1-output-od').value = outputString[4]
    document.getElementById('calc1-output-sidewall').value = outputString[5]
  }

  const handleClickByWeight = () => {
    let a = parseFloat(document.getElementById('calc2-input-weight').value)
    let o = parseFloat(document.getElementById('calc2-input-width').value)
    let n = parseFloat(document.getElementById('calc2-input-thickness').value)

    // Check Input
    let anyErrors = false
    if (a === '' || isNaN(a) || a === null || a < 100 || a > 80000) {
      setCalc2errorWeight('Error* Invalid input.')
      anyErrors = true
    }
    if (o === '' || isNaN(o) || o === null || o < 0.25 || o > 84) {
      setCalc2ErrorWidth('Error* Invalid input.')
      anyErrors = true
    }
    if (n === '' || isNaN(n) || n === null || n < 0.005 || n > 0.65) {
      setCalc2ErrorThickness('Error* Invalid input.')
      anyErrors = true
    }
    if (anyErrors) {
      return
    }

    let r = a / o
    let s = a / (o * n * 0.2833 * 12)
    let c =
      2 * Math.sqrt((a / o + Math.PI * Math.pow(16 / 2, 2) * 0.2833) / 0.889562)
    let h = (c - 16) / 2

    let toConvertToString = [r, a, s, 16, c, h]
    let outputString = []

    for (let i = 0; i < 6; i++) {
      if (Math.floor(toConvertToString[i]) == toConvertToString[i]) {
        outputString.push('' + toConvertToString[i])
      } else {
        outputString.push('' + toConvertToString[i].toFixed(1))
      }
    }
    document.getElementById('calc2-output-piw').value = outputString[0]
    document.getElementById('calc2-output-weight').value = outputString[1]
    document.getElementById('calc2-output-lenght').value = outputString[2]
    document.getElementById('calc2-output-id').value = outputString[3]
    document.getElementById('calc2-output-od').value = outputString[4]
    document.getElementById('calc2-output-sidewall').value = outputString[5]
  }

  const handleClickClearCalculator = () => {
    if (pickedCalculator === 'diameter') {
      document.getElementById('calc1-input-id').value = ''
      document.getElementById('calc1-input-od').value = ''
      document.getElementById('calc1-input-width').value = ''
      document.getElementById('calc1-input-thickness').value = ''
      document.getElementById('calc1-output-piw').value = ''
      document.getElementById('calc1-output-weight').value = ''
      document.getElementById('calc1-output-lenght').value = ''
      document.getElementById('calc1-output-id').value = ''
      document.getElementById('calc1-output-od').value = ''
      document.getElementById('calc1-output-sidewall').value = ''
      setCalc1ErrorId('')
      setCalc1ErrorOd('')
      setCalc1ErrorWidth('')
      setCalc1ErrorThickness('')
    }
    if (pickedCalculator === 'weight') {
      document.getElementById('calc2-input-weight').value = ''
      document.getElementById('calc2-input-width').value = ''
      document.getElementById('calc2-input-thickness').value = ''
      document.getElementById('calc2-output-piw').value = ''
      document.getElementById('calc2-output-weight').value = ''
      document.getElementById('calc2-output-lenght').value = ''
      document.getElementById('calc2-output-id').value = ''
      document.getElementById('calc2-output-od').value = ''
      document.getElementById('calc2-output-sidewall').value = ''
      setCalc2errorWeight('')
      setCalc2ErrorWidth('')
      setCalc2ErrorThickness('')
    }
  }

  const [calc1ErrorId, setCalc1ErrorId] = useState('')
  const [calc1ErrorOd, setCalc1ErrorOd] = useState('')
  const [calc1ErrorWidth, setCalc1ErrorWidth] = useState('')
  const [calc1ErrorThickness, setCalc1ErrorThickness] = useState('')

  const [calc2ErrorWeight, setCalc2errorWeight] = useState('')
  const [calc2ErrorWidth, setCalc2ErrorWidth] = useState('')
  const [calc2ErrorThickness, setCalc2ErrorThickness] = useState('')

  const handleCalc1IdChange = () => {
    setCalc1ErrorId('')
  }
  const handleCalc1OdChange = () => {
    setCalc1ErrorOd('')
  }
  const handleCalc1WidthChange = () => {
    setCalc1ErrorWidth('')
  }
  const handleCalc1ThicknessChange = () => {
    setCalc1ErrorThickness('')
  }

  const handleCalc2WeightChange = () => {
    setCalc2errorWeight('')
  }
  const handleCalc2WidthChange = () => {
    setCalc2ErrorWidth('')
  }
  const handleCalc2ThicknessChange = () => {
    setCalc2ErrorThickness('')
  }


  // Load other video if device is Apple
  const videoRef = useRef(null);
  useEffect(() => {
    const isAppleDevice = /(Macintosh|iPad|iPhone|iPod)/i.test(navigator.userAgent);
    const videoElement = videoRef.current;
    const sourceElement = document.createElement('source');

    if (isAppleDevice) {
      sourceElement.src = IntroAnimationApple;
      sourceElement.type = 'video/mp4;codecs=hvc1';
      console.log("Loaded Apple");
      console.log(navigator.userAgent);
    } else {
      sourceElement.src = IntroAnimation;
      sourceElement.type = 'video/webm';
      console.log("Loaded Other");
      console.log(navigator.userAgent);
    }

    videoElement.appendChild(sourceElement);
    videoElement.play();
  }, []);

  // NAVBAR
  const[click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  click?document.body.style.overflow = "hidden":document.body.style.overflow = "auto";

  function scrollToElementById(id) {
    const navbarheight = document.getElementById('navbar-height').offsetHeight;
    const element = document.getElementById(id).offsetTop;
    let scrollAmount = element - navbarheight
    if(window.pageYOffset === 0) scrollAmount += 10
    setClick(false);
    window.scrollTo({
      top : scrollAmount,
      behavior : 'smooth',
    });
  }

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>

    <div className="navbar-overflow">
      <div className='navbar' id="navbar-height">
        <Link to='/' class={`navbar-name ${scrolled ? 'navbar-name-scrolled' : ''}`}>
          <img src={LogoLight} alt="" />
        </Link>
        <div className={`nav-links ${click ? "show-nav-link" : "" } `}>
          <div className="hamburger-width-container">
            <button className='hamburger-close-button'>
              <img src={HamburgerClose} alt="X" onClick={handleClick}/>
            </button>
            <Link to='/services' activeStyle className={`link-text-style b-top b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className='nav-text'>Services</p></Link>
            <Link to='/products' activeStyle className={`link-text-style b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className='nav-text'>Products</p></Link>
            <button to='/' onClick={() => scrollToElementById('calculatorID')} className={`link-text-style b-bottom remove-button-style ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className='nav-text'>Tehnical&nbsp;Data</p></button>
            <Link to='/about' activeStyle className={`link-text-style b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className='nav-text'>About&nbsp;Us</p></Link>
            <Link to='/contact' activeStyle className={`link-text-style b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className='nav-text'>Contact&nbsp;Us</p></Link>
            <Link to="/quote" className={`nav-btn nav-btn-margin ${scrolled ? 'nav-btn-scrolled' : ''}`}>
              Get&nbsp;a&nbsp;quote
            </Link>
            <button className='b-top-light close-button' onClick={handleClick}>
              <p className='nav-text'>Close menu</p>
            </button>
            <div className="number-flex">
              <img src={PhoneIcon} alt="Phone" height={16} width={16} />
              <span className={`navbar-number ${scrolled ? 'navbar-number-scrolled' : ''}`}>
                708-235-8912
              </span>
            </div>

          </div>
        </div>
        <button className={`hamburger-menu-button-white ${scrolled ? 'navbar-menu-button-scrolled' : ''}`} onClick={handleClick}>
          <img src={HamburgerLight} alt="MENU"/>
        </button>
      </div>
    </div>



    <div className="main-container" ref={topRef}>
      <div className="main-video-container">
        <div className="stuff-on-video">
          <div className="stuff-text">
            <h1>
              Leading <b>metal service</b> center
            </h1>
            <p>
              Tandem metals will do “whatever it takes” to keep its customers
              competitive in the global arena.
            </p>
          </div>
          <div className="stuff-button-container">
            <Link to="/services" className="btn-blue ">
              View&nbsp;our&nbsp;Services
            </Link>
            <Link to="/contact" className="btn-dark landing-btn-margin ">
              Contact&nbsp;us
            </Link>
          </div>
        </div>
        {/* <div className="video-container" style={{ backgroundColor }}> */}
        <div className="video-container">
          <video
            ref={videoRef}
            className="intro-video"
            autoPlay
            muted
            playsInline
            id="container"
          >
            {/* <source src={IntroAnimationApple} type='video/mov; codecs=hvc1'/>
            <source src={IntroAnimation} type="video/webm"/> */}
          </video>

          {/* <video autoplay loop muted playsinline class="video-background ">
            <source src="../assets/animations/Tandem-Sheets-FinalRender.webm" type="video/mp4" />
          </video> */}
        </div>
      </div>
      {/* <canvas ref={canvasRef} style={{ display: 'none' }}/> */}
      <div className="second-background">
        {' '}
        {/*bilo klass*/}
        <div className="second-container">
          <div className="sec-container-text-row">
            {' '}
            {/*bilo klass*/}
            <div className="sec-par">
              {' '}
              {/*bilo klass*/}
              <h1>Innovative metal solutions for a world in motion.</h1>
            </div>
            <div className="sec-par">
              {' '}
              {/*bilo klass*/}
              <p>
                Customer satisfaction is our top priority, and we go above and
                beyond to ensure that every client is happy with our services.
                From the moment you contact us, we will work closely with you to
                understand your specific needs and provide personalized
                solutions that exceed your expectations. Our team is committed
                to delivering exceptional service and will go the extra mile to
                ensure your satisfaction.
              </p>
            </div>
          </div>
          <div className="blue-container">
            {' '}
            {/*bilo klass*/}
            <div className="blue-box">
              <div className="blue-box-cell blue-cell-left blue-cell-first">
                <h3>30 Years</h3>
                <p>
                  <b className="blue-cell-bold">
                    Rich history of serving our customers
                  </b>{' '}
                  for more than three decades.
                </p>
              </div>
              <div className="blue-box-cell blue-cell-middle">
                <h3>350+</h3>
                <p>
                  <b className="blue-cell-bold">
                    Customers have chosen Tandem Metals
                  </b>{' '}
                  for our exceptional metal services
                </p>
              </div>
              <div className="blue-box-cell blue-cell-middle">
                <h3>130k</h3>
                <p>
                  <b className="blue-cell-bold">Square feet of space</b>{' '}
                  accommodating inventory & machinery
                </p>
              </div>
              <div className="blue-box-cell blue-cell-right blue-cell-last">
                <h3>100lbs</h3>
                <p>
                  <b className="blue-cell-bold">Minimal order</b> of a
                  particular thickness at a reasonable price
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services-background">
        <div className="services-container remove-bottom-margin-s-screen">
          <span>SERVICES</span>
          <div className="services-first-row">
            <div className="services-first-2columns margin-between-two-headings">
              <h1>Professional services for our clients</h1>
              <p>
                Tandem metals provide reliable and cost-effective metal products
                that meet the highest industry standards, backed by our
                commitment to quality and customer satisfaction.
              </p>
            </div>
            <div className="services-first-2columns">
              <Link
                to="/services"
                className="button-blue remove-top-margin-s-screen"
              >
                View&nbsp;our&nbsp;Services
              </Link>
            </div>
          </div>
        </div>
        <div className="links-services">
          <Link
            to={'/services'}
            state={{ serviceToShow: 'SlittingComponent' }}
            className="rework-single-service-link"
          >
            <img src={Slitting} alt="Slitting" className="service-rework-img" />
            <div className="rework-stuff-on-box">
              <h3 className="rework-service-heading">Slitting</h3>
              <p className="rework-services-text">Slitting metal sheets</p>
            </div>
            <div className="rework-white-box">
              <img src={RightTriangle} alt=">" className="rework-triangle" />
            </div>
          </Link>
          <Link
            to={'/services'}
            state={{ serviceToShow: 'RollingComponent' }}
            className="rework-single-service-link"
          >
            <img src={Rolling} alt="Rolling" className="service-rework-img" />
            <div className="rework-stuff-on-box">
              <h3 className="rework-service-heading">Rolling</h3>
              <p className="rework-services-text">Cold Rolling And Reduction</p>
            </div>
            <div className="rework-white-box">
              <img src={RightTriangle} alt=">" className="rework-triangle" />
            </div>
          </Link>
          <Link
            to={'/services'}
            state={{ serviceToShow: 'EdgingComponent' }}
            className="rework-single-service-link"
          >
            <img src={Edging} alt="Edging" className="service-rework-img" />
            <div className="rework-stuff-on-box">
              <h3 className="rework-service-heading">Edge conditioning</h3>
              <p className="rework-services-text">Edge to your requirements</p>
            </div>
            <div className="rework-white-box">
              <img src={RightTriangle} alt=">" className="rework-triangle" />
            </div>
          </Link>
          <Link
            to={'/services'}
            state={{ serviceToShow: 'AnnealingComponent' }}
            className="rework-single-service-link"
          >
            <img
              src={Annealing}
              alt="Annealing"
              className="service-rework-img"
            />
            <div className="rework-stuff-on-box">
              <h3 className="rework-service-heading">Annealing</h3>
              <p className="rework-services-text">Heat treatment process</p>
            </div>
            <div className="rework-white-box">
              <img src={RightTriangle} alt=">" className="rework-triangle" />
            </div>
          </Link>
          <Link
            to={'/services'}
            state={{ serviceToShow: 'CutToLenghtComponent' }}
            className="rework-single-service-link"
          >
            <img
              src={CutToLenght}
              alt="CutToLenght"
              className="service-rework-img"
            />
            <div className="rework-stuff-on-box">
              <h3 className="rework-service-heading">Cut To Length</h3>
              <p className="rework-services-text">Cutting in any dimension</p>
            </div>
            <div className="rework-white-box">
              <img src={RightTriangle} alt=">" className="rework-triangle" />
            </div>
          </Link>
          <Link
            to={'/services'}
            state={{ serviceToShow: 'TollProcessingComponent' }}
            className="rework-single-service-link"
          >
            <img
              src={TollProcessing}
              alt="TollProcessing"
              className="service-rework-img"
            />
            <div className="rework-stuff-on-box">
              <h3 className="rework-service-heading">Toll Processing</h3>
              <p className="rework-services-text">According to your specs</p>
            </div>
            <div className="rework-white-box">
              <img src={RightTriangle} alt=">" className="rework-triangle" />
            </div>
          </Link>
          <Link
            to={'/services'}
            state={{ serviceToShow: 'AnalyticalLaboratoryComponent' }}
            className="rework-single-service-link"
          >
            <img
              src={AnalyticalLaboratory}
              alt="AnalyticalLaboratory"
              className="service-rework-img"
            />
            <div className="rework-stuff-on-box">
              <h3 className="rework-service-heading">Analytical laboratory</h3>
              <p className="rework-services-text">Full laboratory analysis</p>
            </div>
            <div className="rework-white-box">
              <img src={RightTriangle} alt=">" className="rework-triangle" />
            </div>
          </Link>
          <Link
            to={'/services'}
            state={{ serviceToShow: 'PackagingComponent' }}
            className="rework-single-service-link"
          >
            <img
              src={Packaging}
              alt="Packaging"
              className="service-rework-img"
            />
            <div className="rework-stuff-on-box">
              <h3 className="rework-service-heading">Packaging</h3>
              <p className="rework-services-text">Packaging and shipping</p>
            </div>
            <div className="rework-white-box">
              <img src={RightTriangle} alt=">" className="rework-triangle" />
            </div>
          </Link>
        </div>
      </div>
      <div className="products-background">
        <div className="products-container">
          <span>PRODUCTS</span>
          <div className="services-first-row">
            <div className="services-first-2columns margin-between-two-headings">
              <h1>High quality metal products</h1>
              <p>
                Tandem metals provide reliable and cost-effective metal products
                that meet the highest industry standards, backed by our
                commitment to quality and customer satisfaction.
              </p>
            </div>
            <div className="services-first-2columns ">
              <Link
                to="/products"
                className="button-blue remove-top-margin-s-screen"
              >
                View&nbsp;our&nbsp;Products
              </Link>
            </div>
          </div>
          <div className="products-grid">
            <Link
              to={'/products'}
              state={{ openedAccoirdion: 'stainless' }}
              className="link-width"
            >
              <div
                className={`product-cell product-cell-left mobile-cell-left ${
                  disableTransition ? 'no-transition' : ''
                }`}
              >
                <img src={stainlessSteel} alt="Stainless-Steel" />
                <h1>Stainless</h1>
                <div className="cell-description">
                  <p>CATEGORY #1</p>
                  <h2>Stainless Steel</h2>
                </div>
              </div>
            </Link>
            <Link
              to={'/products'}
              state={{ openedAccoirdion: 'carbon' }}
              className="link-width"
            >
              <div
                className={`product-cell product-cell-center mobile-cell-right ${
                  disableTransition ? 'no-transition' : ''
                }`}
              >
                <img src={CarbonSteel} alt="Carbon-Steel" />
                <h1>Carbon</h1>
                <div className="cell-description">
                  <p>CATEGORY #1</p>
                  <h2>Carbon Steel</h2>
                </div>
              </div>
            </Link>
            <Link
              to={'/products'}
              state={{ openedAccoirdion: 'coated' }}
              className="link-width"
            >
              <div
                className={`product-cell product-cell-right mobile-cell-left ${
                  disableTransition ? 'no-transition' : ''
                }`}
              >
                <img src={CoatedProducts} alt="coated-Products" />
                <h1>Coated</h1>
                <div className="cell-description">
                  <p>CATEGORY #1</p>
                  <h2>Coated Products</h2>
                </div>
              </div>
            </Link>
            <Link
              to={'/products'}
              state={{ openedAccoirdion: 'alloy' }}
              className="link-width"
            >
              <div
                className={`product-cell product-cell-left mobile-cell-right ${
                  disableTransition ? 'no-transition' : ''
                }`}
              >
                <img src={AlloySteel} alt="Alloy-Steel" />
                <h1>Alloy</h1>
                <div className="cell-description">
                  <p>CATEGORY #1</p>
                  <h2>Alloy Steel</h2>
                </div>
              </div>
            </Link>
            <Link
              to={'/products'}
              state={{ openedAccoirdion: 'red' }}
              className="link-width"
            >
              <div
                className={`product-cell product-cell-center mobile-cell-left dummy-class dummy-class2 before-expand ${
                  collapse ? 'expanded' : ''
                } ${disableTransition ? 'no-transition' : ''} ${
                  disableTransition2 ? 'no-transition2' : ''
                }`}
              >
                <img src={RedMetals} alt="Red-Metals" />
                <h1>Red</h1>
                <div className="cell-description">
                  <p>CATEGORY #1</p>
                  <h2>Red Metals</h2>
                </div>
              </div>
            </Link>
            <Link
              to={'/products'}
              state={{ openedAccoirdion: 'aluminium' }}
              className="link-width"
            >
              <div
                className={`product-cell product-cell-right mobile-cell-right dummy-class dummy-class2 before-expand ${
                  collapse ? 'expanded' : ''
                } ${disableTransition ? 'no-transition' : ''} ${
                  disableTransition2 ? 'no-transition2' : ''
                }`}
              >
                <img src={Aluminium} alt="Aluminium" />
                <h1>Aluminium</h1>
                <div className="cell-description">
                  <p>CATEGORY #3</p>
                  <h2>Aluminium</h2>
                </div>
              </div>
            </Link>
            <Link
              to={'/products'}
              state={{ openedAccoirdion: 'hsla' }}
              className="link-width"
            >
              <div
                className={`product-cell product-cell-left mobile-cell-left dummy-class dummy-class2 before-expand ${
                  collapse ? 'expanded' : ''
                } ${disableTransition ? 'no-transition' : ''} ${
                  disableTransition2 ? 'no-transition2' : ''
                }`}
              >
                <img src={HSLA} alt="HSLA" />
                <h1>HSLA</h1>
                <div className="cell-description">
                  <p>CATEGORY #3</p>
                  <h2>HSLA</h2>
                </div>
              </div>
            </Link>
            <Link
              to={'/products'}
              state={{ openedAccoirdion: 'hrpo' }}
              className="link-width"
            >
              <div
                className={`product-cell product-cell-center mobile-cell-right dummy-class dummy-class2 before-expand ${
                  collapse ? 'expanded' : ''
                } ${disableTransition ? 'no-transition' : ''} ${
                  disableTransition2 ? 'no-transition2' : ''
                }`}
              >
                <img src={HRPO} alt="HRPO" />
                <h1>HRPO</h1>
                <div className="cell-description">
                  <p>CATEGORY #3</p>
                  <h2>HRPO</h2>
                </div>
              </div>
            </Link>
            <div className="product-cell-remove-hover product-cell-last product-cell-left">
              <div className='product-cell-last-flex'>
                <p className='last-cell-text-1'>+ Other</p>
                <p className='last-cell-text-2'>(upon request)</p>
              </div>
            </div>
            <div className="show-more-button">
              <button onClick={buttonHandler} className="show-more-margin">
                {buttonText}
                <img
                  src={UpArrow}
                  alt="Arrow"
                  className={`arrow-button ${collapse ? 'expanded' : ''}`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="calculator-background" ref={refTwo} id="calculatorID">
        <div className="calculator-container">
          <span>TECHNICAL DATA</span>
          <div className="services-first-row margin-between-two-headings">
            <div className="services-first-2columns remove-top-margin-s-screen">
              <h1>PIW Calculator</h1>
            </div>
            <div className="services-first-2columns ">
              <p className="calc-description">
                Tandem metals provide reliable and cost-effective metal products
                that meet the highest industry standards, backed by our
                commitment to quality and customer satisfaction.
              </p>
            </div>
          </div>
          <div className="calculator-picker">
            <button
              onClick={() => CalculatorPickHandler('diameter')}
              className={`calc-picker-btn ${
                pickedCalculator === 'diameter'
                  ? 'selected-color'
                  : 'deselected-color'
              }`}
            >
              By coil diameter
            </button>
            <button
              onClick={() => CalculatorPickHandler('weight')}
              className={`calc-picker-btn ${
                pickedCalculator === 'weight'
                  ? 'selected-color'
                  : 'deselected-color'
              }`}
            >
              By coil weight
            </button>
          </div>
          <div
            className={`calculator-by-diameter ${
              pickedCalculator === 'diameter'
                ? 'show-calculator1'
                : 'hide-calculator'
            }`}
          >
            <div className="calculator-input">
              <p className="input-style1">
                ID <span className="input-style2">[16-36]</span>
              </p>
              <div className="calculator-single-input-container">
                <input
                  type="number"
                  className={`text-input ${
                    calc1ErrorId === 'Error* Invalid input.'
                      ? 'calculator-error-border'
                      : ''
                  }`}
                  id="calc1-input-id"
                  onChange={handleCalc1IdChange}
                />
                <p
                  className={`calculator-error-text ${
                    calc1ErrorId === 'Error* Invalid input.'
                      ? 'show-error-text'
                      : ''
                  }`}
                >
                  {calc1ErrorId}
                </p>
              </div>

              <p className="input-style1">
                OD <span className="input-style2">[ID-84]</span>
              </p>
              <div className="calculator-single-input-container">
                <input
                  type="number"
                  className={`text-input ${
                    calc1ErrorOd === 'Error* Invalid input.'
                      ? 'calculator-error-border'
                      : ''
                  }`}
                  id="calc1-input-od"
                  onChange={handleCalc1OdChange}
                />
                <p
                  className={`calculator-error-text ${
                    calc1ErrorOd === 'Error* Invalid input.'
                      ? 'show-error-text'
                      : ''
                  }`}
                >
                  {calc1ErrorOd}
                </p>
              </div>

              <p className="input-style1">
                WIDTH <span className="input-style2">[0.250 - 84]</span>
              </p>
              <div className="calculator-single-input-container">
                <input
                  type="number"
                  className={`text-input ${
                    calc1ErrorWidth === 'Error* Invalid input.'
                      ? 'calculator-error-border'
                      : ''
                  }`}
                  id="calc1-input-width"
                  onChange={handleCalc1WidthChange}
                />
                <p
                  className={`calculator-error-text ${
                    calc1ErrorWidth === 'Error* Invalid input.'
                      ? 'show-error-text'
                      : ''
                  }`}
                >
                  {calc1ErrorWidth}
                </p>
              </div>

              <p className="input-style1">
                THICKNESS <span className="input-style2">[0.005 - 0.650]</span>
              </p>
              <div className="calculator-single-input-container">
                <input
                  type="number"
                  className={`text-input ${
                    calc1ErrorThickness === 'Error* Invalid input.'
                      ? 'calculator-error-border'
                      : ''
                  }`}
                  id="calc1-input-thickness"
                  onChange={handleCalc1ThicknessChange}
                />
                <p
                  className={`calculator-error-text ${
                    calc1ErrorThickness === 'Error* Invalid input.'
                      ? 'show-error-text'
                      : ''
                  }`}
                >
                  {calc1ErrorThickness}
                </p>
              </div>

              <div className="calculator-buttons">
                <button
                  className="calculate-btn-blue"
                  onClick={handleClickByDiameter}
                >
                  Calculate
                </button>
                <button
                  className="calculate-btn-blue clear-btn-white"
                  onClick={handleClickClearCalculator}
                >
                  Clear
                </button>
              </div>
            </div>
            <span className="your-result-text">YOUR RESULTS:</span>
            <span className="calculator-output">
              <div className="output-field">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc1-output-piw"
                />
                <span className="input-style1">PIW</span>
                <span className="input-style2">[lbs.]</span>
              </div>
              <div className="output-field">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc1-output-weight"
                />
                <span className="input-style1">weight</span>
                <span className="input-style2">[lbs.]</span>
              </div>
              <div className="output-field">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc1-output-lenght"
                />
                <span className="input-style1">length</span>
                <span className="input-style2">[ft.]</span>
              </div>
              <div className="output-field negative-margin">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc1-output-id"
                />
                <span className="input-style1">ID</span>
              </div>
              <div className="output-field negative-margin">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc1-output-od"
                />
                <span className="input-style1">OD</span>
              </div>
              <div className="output-field negative-margin">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc1-output-sidewall"
                />
                <span className="input-style1">SIDEWALL</span>
              </div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </span>
          </div>
          <div
            className={`calculator-by-weight ${
              pickedCalculator === 'weight'
                ? 'show-calculator1'
                : 'hide-calculator'
            }`}
          >
            <div className="calculator-input">
              <p className="input-style1">
                WEIGHT <span className="input-style2">[100-80 000]</span>
              </p>
              <div className="calculator-single-input-container">
                <input
                  type="number"
                  className={`text-input ${
                    calc2ErrorWeight === 'Error* Invalid input.'
                      ? 'calculator-error-border'
                      : ''
                  }`}
                  id="calc2-input-weight"
                  onChange={handleCalc2WeightChange}
                />
                <p
                  className={`calculator-error-text ${
                    calc2ErrorWeight === 'Error* Invalid input.'
                      ? 'show-error-text'
                      : ''
                  }`}
                >
                  {calc2ErrorWeight}
                </p>
              </div>

              <p className="input-style1">
                WIDTH <span className="input-style2">[0.250-84]</span>
              </p>
              <div className="calculator-single-input-container">
                <input
                  type="number"
                  className={`text-input ${
                    calc2ErrorWidth === 'Error* Invalid input.'
                      ? 'calculator-error-border'
                      : ''
                  }`}
                  id="calc2-input-width"
                  onChange={handleCalc2WidthChange}
                />
                <p
                  className={`calculator-error-text ${
                    calc2ErrorWidth === 'Error* Invalid input.'
                      ? 'show-error-text'
                      : ''
                  }`}
                >
                  {calc2ErrorWidth}
                </p>
              </div>

              <p className="input-style1">
                THICKNESS <span className="input-style2">[0.005 - 0.650]</span>
              </p>
              <div className="calculator-single-input-container">
                <input
                  type="number"
                  className={`text-input ${
                    calc2ErrorThickness === 'Error* Invalid input.'
                      ? 'calculator-error-border'
                      : ''
                  }`}
                  id="calc2-input-thickness"
                  onChange={handleCalc2ThicknessChange}
                />
                <p
                  className={`calculator-error-text ${
                    calc2ErrorThickness === 'Error* Invalid input.'
                      ? 'show-error-text'
                      : ''
                  }`}
                >
                  {calc2ErrorThickness}
                </p>
              </div>

              <div className="calculator-buttons">
                <button
                  className="calculate-btn-blue"
                  onClick={handleClickByWeight}
                >
                  Calculate
                </button>
                <button
                  className="calculate-btn-blue clear-btn-white"
                  onClick={handleClickClearCalculator}
                >
                  Clear
                </button>
              </div>
            </div>
            <span className="your-result-text">YOUR RESULTS:</span>
            <span className="calculator-output">
              <div className="output-field">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc2-output-piw"
                />
                <span className="input-style1">PIW</span>
                <span className="input-style2">[lbs.]</span>
              </div>
              <div className="output-field">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc2-output-weight"
                />
                <span className="input-style1">weight</span>
                <span className="input-style2">[lbs.]</span>
              </div>
              <div className="output-field">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc2-output-lenght"
                />
                <span className="input-style1">length</span>
                <span className="input-style2">[ft.]</span>
              </div>
              <div className="output-field negative-margin">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc2-output-id"
                />
                <span className="input-style1">ID</span>
              </div>
              <div className="output-field negative-margin">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc2-output-od"
                />
                <span className="input-style1">OD</span>
              </div>
              <div className="output-field negative-margin">
                <input
                  type="text"
                  className="text-input"
                  disabled
                  id="calc2-output-sidewall"
                />
                <span className="input-style1">SIDEWALL</span>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="information-background">
        <div className="information-container">
          <div className="info-flex-container">
            <div className="certificates-container">
              <h1 className="info-heading">Certificates</h1>
              <p className="info-grey-text">
                We like to be a one-stop shop providing all the flat rolled
                processing services you could possibly need, available in
                aluminum, carbon and stainless steel.
              </p>
              <div className="images-and-pdfs">
                <div className="cert-images">
                  <img
                    src={IsoCertificate}
                    alt="IsoCertificate"
                    className="img-cert-iso"
                  />
                  <img
                    src={PrecisionMetalformingAssociation}
                    alt="PrecisionMetalformingAssociation"
                    className="img-cert-proof"
                  />
                </div>
                <div className="cert-pdfs">
                  <a
                    href={PdfIsoCertificate}
                    download="Iso Certificate"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cert-download-container"
                  >
                    <div className="download-arrow">
                      <img src={DownloadArrow} alt="Download" />
                    </div>
                    <span className="download-button">ISO 9001: 2015 PDF</span>
                  </a>
                  <a
                    href={PdfProofOfCertificate}
                    download="Proof of Certificate"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cert-download-container"
                  >
                    <div className="download-arrow">
                      <img src={DownloadArrow} alt="Download" />
                    </div>
                    <span className="download-button">
                      Proof of certificate PDF
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="contact-container">
              <h1 className="info-heading contact-extra-margin">Contact</h1>
              <h5 className="info-blue-text">Location</h5>
              <p className="info-grey-text">
                Tandem Metals, Inc. 1149 S. Central Ave University Park, IL
                60484
              </p>
              <h5 className="info-blue-text">Business hours:</h5>
              <p className="info-grey-text">
                8:00 am - 5:00 pm M through F or by appointment
              </p>
              <h5 className="info-blue-text">Phone number:</h5>
              <p className="info-grey-text">708-235- 8912</p>
              <h5 className="info-blue-text">Shipping information:</h5>
              <p className="info-grey-text">
                Monday to Friday 7AM to 4PM Including holidays and hours Side
                unload, rear fork 25000 Max
              </p>
            </div>
            <div className="map-container">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    className="gmap_iframe"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=tandem metals inc&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="back-on-top-button">
            <button onClick={() => scrollToDiv(topRef)}>
              Back on top
              <img
                src={UpArrow}
                alt="Arrow"
                className="back-on-top back-on-top-arrow"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default LandingPage
