import React from 'react';
import ContactUs from '../components/ContactUs';
import NavbarDark from '../components/NavbarDark';
import Footer from '../components/Footer';


const Contact = () => {
  return (
    <>
    <NavbarDark />
    <ContactUs />
    <Footer />
    </>
  )
}

export default Contact