import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './css/Quote.css';
import NavbarDark from './NavbarDark';

import DropDownarrow from '../assets/svg/Drop-Down-Arrow.svg';
import CircleCheckBox from '../assets/svg/Checkbox-Circle.svg';
import CheckboxChecked from '../assets/svg/Checkbox-checked.svg';

const QuoteForm = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function waitForButtonClick(buttonId) {
    return new Promise((resolve, reject) => {
      const button = document.getElementById(buttonId);
      if (!button) {
        reject(new Error(`Button with id ${buttonId} not found`));
        return;
      }
      button.addEventListener('click', () => {
        resolve();
      });
    });
  }


  const [pickedCalculator, setPickedCalculator] = useState('regular');
  function CalculatorPickHandler(pickedCalculator){
    if(pickedCalculator === 'regular'){
      setPickedCalculator('regular');
    }else{
      setPickedCalculator('toll');
    }
  }

  // Regular form
  const [selectedOption, setSelectedOption] = useState('Coil');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if(selectedOption==='Coil'){
      setLength('');
    }
  }

  // DropDowns
  const [dropDownCategory, setDropDownCategory] = useState(false);
  function categoryExpandHandler(){
    setDropDownCategory(!dropDownCategory);
  }
  // const [categorySelected, setCategorySelected] = useState('Category');
  const [categorySelected, setCategorySelected] = useState('Stainless Steel');

    useEffect(() => {
      document.addEventListener("click", handleClickOutSideCategory, true);

      return () => {
        document.removeEventListener("click", handleClickOutSideCategory, true);
      };
    }, [])

    const refOne = useRef(null);

    const handleClickOutSideCategory = (e) => {
      if(!refOne.current.contains(e.target)){
        setDropDownCategory(false)
      }
    }


    const handleCategoryChange = (event) => {
      setCategorySelected(event.target.value);
      setGradeSelected(null);
      setGradeSelectedText('Grade');
    }

    // Grade
    const [dropDownGrade, setDropDownGrade] = useState(false);
    function gradeExpandHandler(){
      setDropDownGrade(!dropDownGrade);
    }
    const [gradeSelected, setGradeSelected] = useState('Grade');
    const [gradeSelectedText, setGradeSelectedText] = useState('Grade');
    
    useEffect(() => {
      document.addEventListener("click", handleClickOutSideGrade, true);

      return () => {
        document.removeEventListener("click", handleClickOutSideGrade, true);
      };
    }, [])
    
    const refTwo = useRef(null);
    
    const handleClickOutSideGrade = (e) => {
      if(!refTwo.current.contains(e.target)){
        setDropDownGrade(false)
      }
    }
    
    const handleGradeChange = (event) => {
      setGradeSelected(event.target.value);
      setGradeSelectedText(event.target.value);
    }

    // Temper
    const [dropDownTemper, setDropDownTemper] = useState(false);
    function temperExpandHandler(){
      setDropDownTemper(!dropDownTemper);
    }
    const [temperSelected, setTemperSelected] = useState('Temper');
    
    useEffect(() => {
      document.addEventListener("click", handleClickOutSideTemper, true);

      return () => {
        document.removeEventListener("click", handleClickOutSideTemper, true);
      };
    }, [])
    
    const refThree = useRef(null);
    
    const handleClickOutSideTemper = (e) => {
      if(!refThree.current.contains(e.target)){
        setDropDownTemper(false)
      }
    }

    const handleTemperChange = (event) => {
      setTemperSelected(event.target.value);
    }

    // Edge
    const [dropDownEdge, setDropDownEdge] = useState(false);
    function edgeExpandHandler(){
      setDropDownEdge(!dropDownEdge);
    }
    const [edgeSelected, setEdgeSelected] = useState('Edge');
    
    useEffect(() => {
      document.addEventListener("click", handleClickOutSideEdge, true);

      return () => {
        document.removeEventListener("click", handleClickOutSideEdge, true);
      };
    }, [])
    
    const refFour = useRef(null);
    
    const handleClickOutSideEdge = (e) => {
      if(!refFour.current.contains(e.target)){
        setDropDownEdge(false)
      }
    }
  
    const handleEdgeChange = (event) => {
      setEdgeSelected(event.target.value);
    }

  // DropDowns Scroll on mouse wheel
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();

      event.currentTarget.scrollBy({
        top: event.deltaY > 0 ? 49 : -49,
        behavior: 'instant',
      });
    };
    const scrollableElementIds = [
      'scrollableElement1',
      'scrollableElement2',
      'scrollableElement3',
      'scrollableElement4',
      'scrollableElement5',
    ];
    scrollableElementIds.forEach((elementId) => {
      const scrollableElement = document.getElementById(elementId);
      scrollableElement.addEventListener('wheel', handleScroll);
    });
    return () => {
      scrollableElementIds.forEach((elementId) => {
        const scrollableElement = document.getElementById(elementId);
        if (scrollableElement) {
          scrollableElement.removeEventListener('wheel', handleScroll);
        }
      });
    };
  }, []);

  // DropDownsEnd

  const [length, setLength] = useState('');
  const [gauge, setGauge] = useState('');
  const [finish, setFinish] = useState('');
  const [coilOd, setCoilOd] = useState('');
  const [width, setWidth] = useState('');
  const [coilId, setCoilId] = useState('');
  const [quantity, setQuantity] = useState('');


  const [name1, setName1] = useState('');
  const [email1, setEmail1] = useState('');
  const [telephone1, setTelephone1] = useState('');
  const [compnay1, setCompany1] = useState('');
  const [message1, setMessage1] = useState('');

  
  // const decimalPattern = /^([-+]?[0-9]+(\.[0-9]*)?)?$/;
  const decimalPattern = /^([-+]?\d*(\.\d*)?|\.\d*)$/;



  const setLengthHandler = (event) => {
    console.log(pickedCalculator)
    const amount = event.target.value;
    if(decimalPattern.test(amount)) setLength(amount);
  }

  const setGaugeHandler = (event) => {
    const amount = event.target.value;
    if(decimalPattern.test(amount)) setGauge(amount);
  }

  const setCoilOdHandler = (event) => {
    const amount = event.target.value;
    if(decimalPattern.test(amount)) setCoilOd(amount);
  }

  const setWidthHandler = (event) => {
    const amount = event.target.value;
    if(decimalPattern.test(amount)) setWidth(amount);
  }

  const setCoilIdHandler = (event) => {
    const amount = event.target.value;
    if(decimalPattern.test(amount)) setCoilId(amount);
  }

  const setQuantityHandler = (event) => {
    const amount = event.target.value;
    if(decimalPattern.test(amount)) setQuantity(amount);
  }


  const [checkboxValues, setCheckboxValues] = useState({
    slittingCB: false,
    rollingCB: false,
    annealingCB: false,
    edgingCB: false,
    cutToLengthCB: false,
    labTestingCB: false,
    packagingCB: false
  })

  const [name2, setName2] = useState('');
  const [email2, setEmail2] = useState('');
  const [telephone2, setTelephone2] = useState('');
  const [compnay2, setCompany2] = useState('');
  const [message2, setMessage2] = useState('');

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues({ ...checkboxValues, [name]: checked });
  };

  // Clear buttons
  const ClearRegularOrder = () => {
    setSelectedOption('Coil')
    setDropDownCategory(false)
    setCategorySelected('Stainless Steel')
    setDropDownGrade(false)
    setGradeSelected(null)
    setGradeSelectedText('Grade')
    setTemperSelected('Temper')
    setEdgeSelected('Edge')
    setLength('')
    setGauge('')
    setFinish('')
    setCoilOd('')
    setWidth('')
    setCoilId('')
    setQuantity('')
    setName1('')
    setEmail1('')
    setTelephone1('')
    setCompany1('')
    setMessage1('')
  }

  const ClearTollProces = () => {
    setCheckboxValues({
      slittingCB: false,
      rollingCB: false,
      annealingCB: false,
      edgingCB: false,
      cutToLenthCB: false,
      labTestingCB: false,
      packagingCB: false
    });
    setName2('');
    setEmail2('');
    setTelephone2('');
    setCompany2('');
    setMessage2('');
  }


  //HANDLE FORM SUBMIT - COIL
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    const productType = data['Product-Type'];
    let lengthPrint = "" 
    if(productType==='Sheet'){
      const length = data['Length'];
      lengthPrint = `<p>Length: ${length}</p>`
    }
    const category = data['Category'];
    const grade = data['Grade'];
    const temper = data['Temper'];
    const edge = data['Edge'];
    const gauge = data['Gauge'];
    const finish = data['Finish'];
    const coilOD = data['CoilOD'];
    const width = data['Width'];
    const coilID = data['CoilID'];
    const quantity = data['Quantity'];
    const name = data['Name'];
    const email = data['Email'];
    const telephone = data['Telephone'];
    const company = data['Company'];
    const message = data['message'];
    const config = {
      SecureToken : 'd27318ef-9c8a-4647-a92b-4e7204bfa6a2',
      To : 'info@tandemmetals.com',
      From : `info@tandemmetals.com`,
      Subject : "Quote form",
      Body :`      
        <h2>Regular order</h2>
        <br />
        <h3>Product type: ${productType}</h3>
        ${lengthPrint}
        <br />
        <h3>Product specifications</h3>
        <p>Category: ${category}</p>
        <p>Grade: ${grade}</p>
        <p>Temper: ${temper}</p>
        <p>Edge: ${edge}</p>
        <br />
        <h3>Details</h3>
        <p>Gauge: ${gauge}</p>
        <p>Finish: ${finish}</p>
        <p>Coil OD: ${coilOD}</p>
        <p>Width: ${width}</p>
        <p>Coil ID: ${coilID}</p>
        <p>Quantity: ${quantity}</p>
        <br />
        <h3>Contact information</h3>
        <p>Name: ${name}</p>
        <p>Email: ${email}</p>
        <p>Telephone: ${telephone}</p>
        <p>Company: ${company}</p>
        <p>Message: ${message}</p>
      `,
    }
    if(window.Email) {
      window.Email.send(config).then(() => alert("Email sent successfully"));
      console.log(config);
    }else{
      console.log("Email not sent");
    }
  };


  //HANDLE FORM SUBMIT - TOLL
  const handleSubmitToll = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);

    const servicesArray = [];
    if (data.hasOwnProperty('slittingCB')) servicesArray.push(data.slittingCB);
    if (data.hasOwnProperty('rollingCB')) servicesArray.push(data.rollingCB);
    if (data.hasOwnProperty('annealingCB')) servicesArray.push(data.annealingCB);
    if (data.hasOwnProperty('edgingCB')) servicesArray.push(data.edgingCB);
    if (data.hasOwnProperty('cutToLengthCB')) servicesArray.push(data.cutToLengthCB);
    if (data.hasOwnProperty('labTestingCB')) servicesArray.push(data.labTestingCB);
    if (data.hasOwnProperty('packagingCB')) servicesArray.push(data.packagingCB);
    const name = data['Name'];
    const email = data['Email'];
    const telephone = data['Telephone'];
    const company = data['Company'];
    const message = data['message'];
    let servicesPrint = '';
    servicesArray.forEach(service => {
      servicesPrint += `<p>${service}</p>`;
    });

    const config = {
      SecureToken : 'd27318ef-9c8a-4647-a92b-4e7204bfa6a2',
      To : 'info@tandemmetals.com',
      From : `info@tandemmetals.com`,
      Subject : "Quote form",
      Body :`
        <h2>Toll Process</h2>
        <br />
        <h3>Selected services</h3>
        ${servicesPrint}
        <br />
        <h3>Contact information</h3>
        <p>Name: ${name}</p>
        <p>Email: ${email}</p>
        <p>Telephone: ${telephone}</p>
        <p>Company: ${company}</p>
        <p>Message: ${message}</p>
      `,
    }
    if(window.Email) {
      window.Email.send(config).then(() => alert("Email sent successfully"));
      console.log(servicesArray);
    }else{
      console.log("Email not sent");
    }
  }

  return (
    <>
      <NavbarDark />
      <div className="quote-bg-container">
        <div className="quote-main-container">
            <div className='make-30-width'>
              <h1 className='main-quote-heading q-margin-bottom-23'>Get a quote today!</h1>
              <p className='main-quote-paragraph q-margin-bottom-70'>We'd love to hear from you! Please don't hesitate to contact us if you have any questions or inquiries about our products and services.</p>
            </div>
            <div className="quote-buttons-picker-container">
              <button onClick={() => CalculatorPickHandler('regular')} className={`calc-picker-btn ${pickedCalculator === 'regular' ? "selected-color" : "deselected-color"}`}>
              Regular Order
              </button>
              <button onClick={() => CalculatorPickHandler('toll')} className={`calc-picker-btn ${pickedCalculator === 'toll' ? "selected-color" : "deselected-color"}`}>
              Toll Process
              </button>
            </div>
            {/* Regular calculator */}
            <div className={`regular-calculator-main-container q-padding-bottom-50 ${pickedCalculator === 'regular' ? "show-calculator" : "hide-calculator"}`}>
              <form className="regular-calculator-content-container" onSubmit={handleSubmit}>
                <p className='quote-calculator-text-styling q-padding-top-50 q-margin-bottom-23'>Select product type:</p>
                <div className="selected-type-row q-padding-bottom-50 quote-bottom-border-splitter">
                  <div className='selected-buttons-row'>
                  
                  <label htmlFor="o2" className={`product-type-radio-label border-radio-first ${selectedOption === 'Coil' ? "picked-radio" : ""}`}>
                    <input type="radio" name="Product-Type" value="Coil" id='o2' checked={selectedOption === 'Coil'}  onChange={handleOptionChange} className='radio-btn'/>
                    <p className='quote-calculator-text-styling'>Coil</p> 
                  </label>
                  <label htmlFor="o1" className={`product-type-radio-label border-radio-second ${selectedOption === 'Sheet' ? "picked-radio" : ""}`}>
                    <input type="radio" name="Product-Type" value="Sheet" id="o1" checked={selectedOption === 'Sheet'} onChange={handleOptionChange} className='radio-btn'/>
                    <p className='quote-calculator-text-styling'>Sheet</p>
                  </label>
                  </div>
                  <div className={`length-input-width ${selectedOption==='Coil' ? 'hide-calculator' : ''}`}>
                    <div className='quote-textbox-container'>
                      <div className='quote-textbox-description'>
                        <p className='quote-calculator-text-styling color-57606D'>Length:</p>
                      </div>
                      
                      <div className='quote-textbox-content'>
                        <input type="text" placeholder='Length' name='Length' value={length} onChange={(event) => setLengthHandler(event)} disabled={selectedOption === 'Coil'} className={`textbox-styling quote-calculator-text-styling make-width-80 color-57606D`}/>
                      </div>
                    </div>
                  </div>
                </div>
                <p className='quote-calculator-text-styling q-padding-top-50 q-margin-bottom-23'>Choose product specifications::</p>
                <div className="selectors-flex-row q-padding-bottom-50 quote-bottom-border-splitter">
                  <div className="select-boxes-row">
                    <div className="select-box">
                    <button ref={refOne} type='button' className='select-box-button quote-calculator-text-styling btn-flex' onClick={categoryExpandHandler}>
                      <span>{categorySelected}</span><img src={DropDownarrow} alt="" /> 
                    </button>
                    <div className={`category-radios ${dropDownCategory ? "show-dropdown bigger-1px" : "hide-dropdown"}`}>
                      <label htmlFor="c1" className='radio-option rounded-top'>
                        <input type="radio" name="Category" value="Stainless Steel" id="c1" checked={categorySelected === 'Stainless Steel'} onChange={handleCategoryChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Stainless steel</p>
                      </label>
                      <label htmlFor="c2" className='radio-option'>
                        <input type="radio" name="Category" value="Carbon Steel" id="c2" checked={categorySelected === 'Carbon Steel'} onChange={handleCategoryChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Carbon Steel</p>
                      </label>
                      <label htmlFor="c3" className='radio-option'>
                        <input type="radio" name="Category" value="Coated" id="c3" checked={categorySelected === 'Coated'} onChange={handleCategoryChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Coated</p>
                      </label>
                      <label htmlFor="c4" className='radio-option'>
                        <input type="radio" name="Category" value="Alloy Steel" id="c4" checked={categorySelected === 'Alloy Steel'} onChange={handleCategoryChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Alloy Steel</p>
                      </label>
                      <label htmlFor="c5" className='radio-option'>
                        <input type="radio" name="Category" value="Red Metals" id="c5" checked={categorySelected === 'Red Metals'} onChange={handleCategoryChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Red Metals</p>
                      </label>
                      <label htmlFor="c6" className='radio-option'>
                        <input type="radio" name="Category" value="Alluminium" id="c6" checked={categorySelected === 'Alluminium'} onChange={handleCategoryChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Alluminium</p>
                      </label>
                      <label htmlFor="c7" className='radio-option rounded-bottom'>
                        <input type="radio" name="Category" value="HSLA" id="c7" checked={categorySelected === 'HSLA'} onChange={handleCategoryChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>HSLA</p>
                      </label>
                    </div>
                    </div>

                    <div className="select-box">
                      {/* <button ref={refTwo} type='button' className={`select-box-button quote-calculator-text-styling btn-flex ${categorySelected==='Category' ? 'grey-out' : ''}`} onClick={gradeExpandHandler}> */}
                      <button ref={refTwo} type='button' className="select-box-button quote-calculator-text-styling btn-flex" onClick={gradeExpandHandler}>
                        <span>{gradeSelectedText}</span><img src={DropDownarrow} alt="" /> 
                      </button>
                      {/* STainless steel */}
                      <div id="scrollableElement1" className={`category-radios ${dropDownGrade ? "show-dropdown" : "hide-dropdown"} ${categorySelected === "Stainless Steel" ? "show-dropdown" : "hide-dropdown"}`}>
                      <label htmlFor="gA1" className='radio-option rounded-top'>
                        <input type="radio" name="Grade" value="201" id="gA1" checked={gradeSelected === '201'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>201</p>
                      </label>
                      <label htmlFor="gA2" className='radio-option'>
                        <input type="radio" name="Grade" value="202" id="gA2" checked={gradeSelected === '202'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>202</p>
                      </label>
                      <label htmlFor="gA3" className='radio-option'>
                        <input type="radio" name="Grade" value="301" id="gA3" checked={gradeSelected === '301'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>301</p>
                      </label>
                      <label htmlFor="gA4" className='radio-option'>
                        <input type="radio" name="Grade" value="302b" id="gA4" checked={gradeSelected === '302b'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>302b</p>
                      </label>
                      <label htmlFor="gA5" className='radio-option'>
                        <input type="radio" name="Grade" value="303" id="gA5" checked={gradeSelected === '303'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>303</p>
                      </label>
                      <label htmlFor="gA6" className='radio-option'>
                        <input type="radio" name="Grade" value="304" id="gA6" checked={gradeSelected === '304'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>304</p>
                      </label>
                      <label htmlFor="gA7" className='radio-option'>
                        <input type="radio" name="Grade" value="304L" id="gA7" checked={gradeSelected === '304L'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>304L</p>
                      </label>
                      <label htmlFor="gA8" className='radio-option'>
                        <input type="radio" name="Grade" value="305" id="gA8" checked={gradeSelected === '305'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>305</p>
                      </label>
                      <label htmlFor="gA9" className='radio-option'>
                        <input type="radio" name="Grade" value="308" id="gA9" checked={gradeSelected === '308'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>308</p>
                      </label>
                      <label htmlFor="gA10" className='radio-option'>
                        <input type="radio" name="Grade" value="309" id="gA10" checked={gradeSelected === '309'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>309</p>
                      </label>
                      <label htmlFor="gA11" className='radio-option'>
                        <input type="radio" name="Grade" value="309S" id="gA11" checked={gradeSelected === '309S'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>309S</p>
                      </label>
                      <label htmlFor="gA12" className='radio-option'>
                        <input type="radio" name="Grade" value="310" id="gA12" checked={gradeSelected === '310'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>310</p>
                      </label>
                      <label htmlFor="gA13" className='radio-option'>
                        <input type="radio" name="Grade" value="310S" id="gA13" checked={gradeSelected === '310S'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>310S</p>
                      </label>
                      <label htmlFor="gA14" className='radio-option'>
                        <input type="radio" name="Grade" value="314" id="gA14" checked={gradeSelected === '314'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>314</p>
                      </label>
                      <label htmlFor="gA15" className='radio-option'>
                        <input type="radio" name="Grade" value="316" id="gA15" checked={gradeSelected === '316'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>316</p>
                      </label>
                      <label htmlFor="gA16" className='radio-option'>
                        <input type="radio" name="Grade" value="316L" id="gA16" checked={gradeSelected === '316L'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>316L</p>
                      </label>
                      <label htmlFor="gA17" className='radio-option'>
                        <input type="radio" name="Grade" value="317" id="gA17" checked={gradeSelected === '317'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>317</p>
                      </label>
                      <label htmlFor="gA18" className='radio-option'>
                        <input type="radio" name="Grade" value="321" id="gA18" checked={gradeSelected === '321'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>321</p>
                      </label>
                      <label htmlFor="gA19" className='radio-option'>
                        <input type="radio" name="Grade" value="347" id="gA19" checked={gradeSelected === '347'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>347</p>
                      </label>
                      <label htmlFor="gA20" className='radio-option'>
                        <input type="radio" name="Grade" value="348" id="gA20" checked={gradeSelected === '348'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>348</p>
                      </label>
                      <label htmlFor="gA21" className='radio-option'>
                        <input type="radio" name="Grade" value="403" id="gA21" checked={gradeSelected === '403'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>403</p>
                      </label>
                      <label htmlFor="gA22" className='radio-option'>
                        <input type="radio" name="Grade" value="405" id="gA22" checked={gradeSelected === '405'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>405</p>
                      </label>
                      <label htmlFor="gA23" className='radio-option'>
                        <input type="radio" name="Grade" value="410" id="gA23" checked={gradeSelected === '410'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>410</p>
                      </label>
                      <label htmlFor="gA24" className='radio-option'>
                        <input type="radio" name="Grade" value="414" id="gA24" checked={gradeSelected === '414'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>414</p>
                      </label>
                      <label htmlFor="gA25" className='radio-option'>
                        <input type="radio" name="Grade" value="416" id="gA25" checked={gradeSelected === '416'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>416</p>
                      </label>
                      <label htmlFor="gA26" className='radio-option'>
                        <input type="radio" name="Grade" value="416SE" id="gA26" checked={gradeSelected === '416SE'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>416SE</p>
                      </label>
                      <label htmlFor="gA27" className='radio-option'>
                        <input type="radio" name="Grade" value="420" id="gA27" checked={gradeSelected === '420'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>420</p>
                      </label>
                      <label htmlFor="gA28" className='radio-option'>
                        <input type="radio" name="Grade" value="430" id="gA28" checked={gradeSelected === '430'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>430</p>
                      </label>
                      <label htmlFor="gA29" className='radio-option'>
                        <input type="radio" name="Grade" value="430F" id="gA29" checked={gradeSelected === '430F'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>430F</p>
                      </label>
                      <label htmlFor="gA30" className='radio-option'>
                        <input type="radio" name="Grade" value="430F SE" id="gA30" checked={gradeSelected === '430F SE'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>430F SE</p>
                      </label>
                      <label htmlFor="gA31" className='radio-option'>
                        <input type="radio" name="Grade" value="431" id="gA31" checked={gradeSelected === '431'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>431</p>
                      </label>
                      <label htmlFor="gA32" className='radio-option'>
                        <input type="radio" name="Grade" value="440A" id="gA32" checked={gradeSelected === '440A'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>440A</p>
                      </label>
                      <label htmlFor="gA33" className='radio-option'>
                        <input type="radio" name="Grade" value="440B" id="gA33" checked={gradeSelected === '440B'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>440B</p>
                      </label>
                      <label htmlFor="gA34" className='radio-option'>
                        <input type="radio" name="Grade" value="440C" id="gA34" checked={gradeSelected === '440C'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>440C</p>
                      </label>
                      <label htmlFor="gA35" className='radio-option'>
                        <input type="radio" name="Grade" value="446" id="gA35" checked={gradeSelected === '446'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>446</p>
                      </label>
                      <label htmlFor="gA36" className='radio-option'>
                        <input type="radio" name="Grade" value="701" id="gA36" checked={gradeSelected === '701'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>701</p>
                      </label>
                      <label htmlFor="gA37" className='radio-option'>
                        <input type="radio" name="Grade" value="702" id="gA37" checked={gradeSelected === '702'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>702</p>
                      </label>
                      <label htmlFor="gA38" className='radio-option'>
                        <input type="radio" name="Grade" value="17-7PH" id="gA38" checked={gradeSelected === '17-7PH'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>17-7PH</p>
                      </label>
                      </div>
                      {/* Carbon steel */}
                      <div id="scrollableElement2" className={`category-radios ${dropDownGrade ? "show-dropdown" : "hide-dropdown"} ${categorySelected === "Carbon Steel" ? "show-dropdown" : "hide-dropdown"}`}>
                      <label htmlFor="gB1" className='radio-option rounded-top'>
                        <input type="radio" name="Grade" value="1008" id="gB1" checked={gradeSelected === '1008'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1008</p>
                      </label>
                      <label htmlFor="gB2" className='radio-option'>
                        <input type="radio" name="Grade" value="1010" id="gB2" checked={gradeSelected === '1010'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1010</p>
                      </label>
                      <label htmlFor="gB3" className='radio-option'>
                        <input type="radio" name="Grade" value="1012" id="gB3" checked={gradeSelected === '1012'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1012</p>
                      </label>
                      <label htmlFor="gB4" className='radio-option'>
                        <input type="radio" name="Grade" value="1015" id="gB4" checked={gradeSelected === '1015'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1015</p>
                      </label>
                      <label htmlFor="gB5" className='radio-option'>
                        <input type="radio" name="Grade" value="1016" id="gB5" checked={gradeSelected === '1016'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1016</p>
                      </label>
                      <label htmlFor="gB6" className='radio-option'>
                        <input type="radio" name="Grade" value="1017" id="gB6" checked={gradeSelected === '1017'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1017</p>
                      </label>
                      <label htmlFor="gB7" className='radio-option'>
                        <input type="radio" name="Grade" value="1018" id="gB7" checked={gradeSelected === '1018'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1018</p>
                      </label>
                      <label htmlFor="gB8" className='radio-option'>
                        <input type="radio" name="Grade" value="1019" id="gB8" checked={gradeSelected === '1019'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1019</p>
                      </label>
                      {/* <label htmlFor="gB9" className='radio-option'>
                        <input type="radio" name="Grade" value="308" id="gB9" checked={gradeSelected === '308'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>308</p>
                      </label> */}
                      <label htmlFor="gB10" className='radio-option'>
                        <input type="radio" name="Grade" value="1020" id="gB10" checked={gradeSelected === '1020'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1020</p>
                      </label>
                      <label htmlFor="gB11" className='radio-option'>
                        <input type="radio" name="Grade" value="1022" id="gB11" checked={gradeSelected === '1022'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1022</p>
                      </label>
                      <label htmlFor="gB12" className='radio-option'>
                        <input type="radio" name="Grade" value="1023" id="gB12" checked={gradeSelected === '1023'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1023</p>
                      </label>
                      <label htmlFor="gB13" className='radio-option'>
                        <input type="radio" name="Grade" value="1025" id="gB13" checked={gradeSelected === '1025'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1025</p>
                      </label>
                      <label htmlFor="gB14" className='radio-option'>
                        <input type="radio" name="Grade" value="1030" id="gB14" checked={gradeSelected === '1030'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1030</p>
                      </label>
                      <label htmlFor="gB15" className='radio-option'>
                        <input type="radio" name="Grade" value="1035" id="gB15" checked={gradeSelected === '1035'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1035</p>
                      </label>
                      <label htmlFor="gB16" className='radio-option'>
                        <input type="radio" name="Grade" value="1040" id="gB16" checked={gradeSelected === '1040'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1040</p>
                      </label>
                      <label htmlFor="gB17" className='radio-option'>
                        <input type="radio" name="Grade" value="316" id="gB17" checked={gradeSelected === '316'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>316</p>
                      </label>
                      <label htmlFor="gB18" className='radio-option'>
                        <input type="radio" name="Grade" value="1045" id="gB18" checked={gradeSelected === '1045'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1045</p>
                      </label>
                      <label htmlFor="gB19" className='radio-option'>
                        <input type="radio" name="Grade" value="1050" id="gB19" checked={gradeSelected === '1050'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1050</p>
                      </label>
                      <label htmlFor="gB20" className='radio-option'>
                        <input type="radio" name="Grade" value="1060" id="gB20" checked={gradeSelected === '1060'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1060</p>
                      </label>
                      <label htmlFor="gB21" className='radio-option'>
                        <input type="radio" name="Grade" value="1065" id="gB21" checked={gradeSelected === '1065'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1065</p>
                      </label>
                      <label htmlFor="gB22" className='radio-option'>
                        <input type="radio" name="Grade" value="1070" id="gB22" checked={gradeSelected === '1070'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1070</p>
                      </label>
                      <label htmlFor="gB23" className='radio-option'>
                        <input type="radio" name="Grade" value="1074" id="gB23" checked={gradeSelected === '1074'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1074</p>
                      </label>
                      <label htmlFor="gB24" className='radio-option'>
                        <input type="radio" name="Grade" value="1080" id="gB24" checked={gradeSelected === '1080'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1080</p>
                      </label>
                      <label htmlFor="gB25" className='radio-option'>
                        <input type="radio" name="Grade" value="1085" id="gB25" checked={gradeSelected === '1085'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1085</p>
                      </label>
                      <label htmlFor="gB26" className='radio-option'>
                        <input type="radio" name="Grade" value="1095" id="gB26" checked={gradeSelected === '1095'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1095</p>
                      </label>
                     
                      </div>
                      {/* Coated */}
                      <div className={`category-radios ${dropDownGrade ? "show-dropdown" : "hide-dropdown"} ${categorySelected === "Coated" ? "show-dropdown" : "hide-dropdown"}`}>
                      <label htmlFor="gC1" className='radio-option rounded-top'>
                        <input type="radio" name="Grade" value="Hot Dipped Galvanized / Galvannealed" id="gC1" checked={gradeSelected === 'Hot Dipped Galvanized / Galvannealed'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Hot Dipped Galvanized / Galvannealed</p>
                      </label>
                      <label htmlFor="gC2" className='radio-option'>
                        <input type="radio" name="Grade" value="Electro-Galvanized" id="gC2" checked={gradeSelected === 'Electro-Galvanized'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Electro-Galvanized</p>
                      </label>
                      <label htmlFor="gC3" className='radio-option'>
                        <input type="radio" name="Grade" value="Electro- Tin Plate and Tin Coated" id="gC3" checked={gradeSelected === 'Electro- Tin Plate and Tin Coated'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Electro- Tin Plate and Tin Coated</p>
                      </label>
                      <label htmlFor="gC4" className='radio-option'>
                        <input type="radio" name="Grade" value="Aluminized" id="gC4" checked={gradeSelected === 'Aluminized'} onChange={handleGradeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Aluminized</p>
                      </label>


                     
                      </div>
                      {/* Alloy Steel */}
                      <div className={`category-radios ${dropDownGrade ? "show-dropdown" : "hide-dropdown"} ${categorySelected === "Alloy Steel" ? "show-dropdown" : "hide-dropdown"}`}>
                        <label htmlFor="gD1" className='radio-option rounded-top'>
                          <input type="radio" name="Grade" value="4130" id="gD1" checked={gradeSelected === '4130'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>4130</p>
                        </label>
                        <label htmlFor="gD2" className='radio-option'>
                          <input type="radio" name="Grade" value="4140" id="gD2" checked={gradeSelected === '4140'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>4140</p>
                        </label>
                        <label htmlFor="gD3" className='radio-option'>
                          <input type="radio" name="Grade" value="4142" id="gD3" checked={gradeSelected === '4142'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>4142</p>
                        </label>
                        <label htmlFor="gD4" className='radio-option'>
                          <input type="radio" name="Grade" value="5150" id="gD4" checked={gradeSelected === '5150'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>5150</p>
                        </label>
                        <label htmlFor="gD5" className='radio-option'>
                          <input type="radio" name="Grade" value="5160" id="gD5" checked={gradeSelected === '5160'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>5160</p>
                        </label>
                        <label htmlFor="gD6" className='radio-option'>
                          <input type="radio" name="Grade" value="52100" id="gD6" checked={gradeSelected === '52100'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>52100</p>
                        </label>
                        <label htmlFor="gD7" className='radio-option'>
                          <input type="radio" name="Grade" value="6150" id="gD7" checked={gradeSelected === '6150'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>6150</p>
                        </label>
                      </div>
                      {/* Red Steel */}
                      <div id="scrollableElement3" className={`category-radios ${dropDownGrade ? "show-dropdown" : "hide-dropdown"} ${categorySelected === "Red Metals" ? "show-dropdown" : "hide-dropdown"}`}>
                        <label htmlFor="gE1" className='radio-option rounded-top'>
                          <input type="radio" name="Grade" value="101-0" id="gE1" checked={gradeSelected === '101-0'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>101-0</p>
                        </label>
                        <label htmlFor="gE2" className='radio-option'>
                          <input type="radio" name="Grade" value="102-1" id="gE2" checked={gradeSelected === '102-1'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>102-1</p>
                        </label>
                        <label htmlFor="gE3" className='radio-option'>
                          <input type="radio" name="Grade" value="110-0" id="gE3" checked={gradeSelected === '110-0'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>110-0</p>
                        </label>
                        <label htmlFor="gE4" className='radio-option'>
                          <input type="radio" name="Grade" value="110-1" id="gE4" checked={gradeSelected === '110-1'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>110-1</p>
                        </label>
                        <label htmlFor="gE5" className='radio-option'>
                          <input type="radio" name="Grade" value="110-2" id="gE5" checked={gradeSelected === '110-2'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>110-2</p>
                        </label>
                        <label htmlFor="gE6" className='radio-option'>
                          <input type="radio" name="Grade" value="110-3" id="gE6" checked={gradeSelected === '110-3'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>110-3</p>
                        </label>
                        <label htmlFor="gE7" className='radio-option'>
                          <input type="radio" name="Grade" value="110-4" id="gE7" checked={gradeSelected === '110-4'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>110-4</p>
                        </label>
                        <label htmlFor="gE8" className='radio-option'>
                          <input type="radio" name="Grade" value="110-8" id="gE8" checked={gradeSelected === '110-8'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>110-8</p>
                        </label>
                        <label htmlFor="gE9" className='radio-option'>
                          <input type="radio" name="Grade" value="143-4" id="gE9" checked={gradeSelected === '143-4'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>143-4</p>
                        </label>
                        <label htmlFor="gE10" className='radio-option'>
                          <input type="radio" name="Grade" value="194-2" id="gE10" checked={gradeSelected === '194-2'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>194-2</p>
                        </label>
                        <label htmlFor="gE11" className='radio-option'>
                          <input type="radio" name="Grade" value="201-0" id="gE11" checked={gradeSelected === '201-0'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>201-0</p>
                        </label>
                        <label htmlFor="gE12" className='radio-option'>
                          <input type="radio" name="Grade" value="201-1" id="gE12" checked={gradeSelected === '201-1'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>201-1</p>
                        </label>
                        <label htmlFor="gE13" className='radio-option'>
                          <input type="radio" name="Grade" value="230-2" id="gE13" checked={gradeSelected === '230-2'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>230-2</p>
                        </label>
                        <label htmlFor="gE14" className='radio-option'>
                          <input type="radio" name="Grade" value="260-0" id="gE14" checked={gradeSelected === '260-0'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>260-0</p>
                        </label>
                        <label htmlFor="gE15" className='radio-option'>
                          <input type="radio" name="Grade" value="260-1" id="gE15" checked={gradeSelected === '260-1'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>260-1</p>
                        </label>
                        <label htmlFor="gE16" className='radio-option'>
                          <input type="radio" name="Grade" value="260-2" id="gE16" checked={gradeSelected === '260-2'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>260-2</p>
                        </label>
                        <label htmlFor="gE17" className='radio-option'>
                          <input type="radio" name="Grade" value="260-3" id="gE17" checked={gradeSelected === '260-3'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>260-3</p>
                        </label>
                        <label htmlFor="gE18" className='radio-option'>
                          <input type="radio" name="Grade" value="260-4" id="gE18" checked={gradeSelected === '260-4'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>260-4</p>
                        </label>
                        <label htmlFor="gE19" className='radio-option'>
                          <input type="radio" name="Grade" value="260-8" id="gE19" checked={gradeSelected === '260-8'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>260-8</p>
                        </label>
                      </div>
                      {/* Alluminium */}
                      <div id="scrollableElement4" className={`category-radios ${dropDownGrade ? "show-dropdown" : "hide-dropdown"} ${categorySelected === "Alluminium" ? "show-dropdown" : "hide-dropdown"}`}>
                        <label htmlFor="gF1" className='radio-option rounded-top'>
                          <input type="radio" name="Grade" value="1050" id="gF1" checked={gradeSelected === '1050'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>1050</p>
                        </label>
                        <label htmlFor="gF2" className='radio-option'>
                          <input type="radio" name="Grade" value="1100" id="gF2" checked={gradeSelected === '1100'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>1100</p>
                        </label>
                        <label htmlFor="gF3" className='radio-option'>
                          <input type="radio" name="Grade" value="1145" id="gF3" checked={gradeSelected === '1145'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>1145</p>
                        </label>
                        <label htmlFor="gF4" className='radio-option'>
                          <input type="radio" name="Grade" value="1200" id="gF4" checked={gradeSelected === '1200'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>1200</p>
                        </label>
                        <label htmlFor="gF5" className='radio-option'>
                          <input type="radio" name="Grade" value="3002" id="gF5" checked={gradeSelected === '3002'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>3002</p>
                        </label>
                        <label htmlFor="gF6" className='radio-option'>
                          <input type="radio" name="Grade" value="3003" id="gF6" checked={gradeSelected === '3003'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>3003</p>
                        </label>
                        <label htmlFor="gF7" className='radio-option'>
                          <input type="radio" name="Grade" value="3105" id="gF7" checked={gradeSelected === '3105'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>3105</p>
                        </label>
                        <label htmlFor="gF8" className='radio-option'>
                          <input type="radio" name="Grade" value="5005" id="gF8" checked={gradeSelected === '5005'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>5005</p>
                        </label>
                        <label htmlFor="gF9" className='radio-option'>
                          <input type="radio" name="Grade" value="5082" id="gF9" checked={gradeSelected === '5082'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>5082</p>
                        </label>
                        <label htmlFor="gF10" className='radio-option'>
                          <input type="radio" name="Grade" value="5086" id="gF10" checked={gradeSelected === '5086'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>5086</p>
                        </label>
                        <label htmlFor="gF11" className='radio-option'>
                          <input type="radio" name="Grade" value="6061" id="gF11" checked={gradeSelected === '6061'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>6061</p>
                        </label>
                        <label htmlFor="gF12" className='radio-option'>
                          <input type="radio" name="Grade" value="6063" id="gF12" checked={gradeSelected === '6063'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>6063</p>
                        </label>
                        <label htmlFor="gF13" className='radio-option'>
                          <input type="radio" name="Grade" value="7075" id="gF13" checked={gradeSelected === '7075'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>7075</p>
                        </label>
                      </div>
                      {/* Alluminium */}
                      <div className={`category-radios ${dropDownGrade ? "show-dropdown" : "hide-dropdown"} ${categorySelected === "HSLA" ? "show-dropdown" : "hide-dropdown"}`}>
                        <label htmlFor="gG1" className='radio-option rounded-top'>
                          <input type="radio" name="Grade" value="HSLA GR45" id="gG1" checked={gradeSelected === 'HSLA GR45'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>HSLA GR45</p>
                        </label>
                        <label htmlFor="gG2" className='radio-option'>
                          <input type="radio" name="Grade" value="HSLA GR50" id="gG2" checked={gradeSelected === 'HSLA GR50'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>HSLA GR50</p>
                        </label>
                        <label htmlFor="gG3" className='radio-option'>
                          <input type="radio" name="Grade" value="HSLA GR60" id="gG3" checked={gradeSelected === 'HSLA GR60'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>HSLA GR60</p>
                        </label>
                        <label htmlFor="gG4" className='radio-option'>
                          <input type="radio" name="Grade" value="HSLA GR70" id="gG4" checked={gradeSelected === 'HSLA GR70'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>HSLA GR70</p>
                        </label>
                        <label htmlFor="gG5" className='radio-option'>
                          <input type="radio" name="Grade" value="HSLA GR80" id="gG5" checked={gradeSelected === 'HSLA GR80'} onChange={handleGradeChange} className='radio-btn'/>
                          <p className='quote-calculator-text-styling center-text'>HSLA GR80</p>
                        </label>
                      </div>

                    </div>

                    <div className="select-box">
                    <button ref={refThree} type='button' className='select-box-button quote-calculator-text-styling btn-flex' onClick={temperExpandHandler}>
                      <span>{temperSelected}</span><img src={DropDownarrow} alt="" /> 
                    </button>
                    <div className={`category-radios ${dropDownTemper ? "show-dropdown" : "hide-dropdown"}`}>
                      <label htmlFor="t1" className='radio-option rounded-top'>
                        <input type="radio" name="Temper" value="DQ Annealed" id="t1" checked={temperSelected === 'DQ Annealed'} onChange={handleTemperChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>DQ Annealed</p>
                      </label>
                      <label htmlFor="t2" className='radio-option rounded-top'>
                        <input type="radio" name="Temper" value="1/4 Hard" id="t2" checked={temperSelected === '1/4 Hard'} onChange={handleTemperChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1/4 Hard</p>
                      </label>
                      <label htmlFor="t3" className='radio-option rounded-top'>
                        <input type="radio" name="Temper" value="1/2 Hard" id="t3" checked={temperSelected === '1/2 Hard'} onChange={handleTemperChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>1/2 Hard</p>
                      </label>
                      <label htmlFor="t4" className='radio-option rounded-top'>
                        <input type="radio" name="Temper" value="3/4 Hard" id="t4" checked={temperSelected === '3/4 Hard'} onChange={handleTemperChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>3/4 Hard</p>
                      </label>
                      <label htmlFor="t5" className='radio-option rounded-top'>
                        <input type="radio" name="Temper" value="Full Hard" id="t5" checked={temperSelected === 'Full Hard'} onChange={handleTemperChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Full Hard</p>
                      </label>
                    </div>
                    </div>

                    <div className="select-box">
                  <button ref={refFour} type='button' className='select-box-button quote-calculator-text-styling btn-flex' onClick={edgeExpandHandler}>
                      <span>{edgeSelected}</span><img src={DropDownarrow} alt="" /> 
                    </button>
                    <div id="scrollableElement5" className={`category-radios ${dropDownEdge ? "show-dropdown" : "hide-dropdown"}`}>
                      <label htmlFor="e1" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Round edge" id="e1" checked={edgeSelected === 'Round edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Round edge</p>
                      </label>
                      <label htmlFor="e2" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Square edge" id="e2" checked={edgeSelected === 'Square edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Square edge</p>
                      </label>
                      <label htmlFor="e3" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Mill edge" id="e3" checked={edgeSelected === 'Mill edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Mill edge</p>
                      </label>
                      <label htmlFor="e4" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Rounded edge" id="e4" checked={edgeSelected === 'Rounded edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Rounded edge</p>
                      </label>
                      <label htmlFor="e5" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Deburred edge" id="e5" checked={edgeSelected === 'Deburred edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Deburred edge</p>
                      </label>
                      <label htmlFor="e6" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Edge" id="e6" checked={edgeSelected === 'Edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Edge</p>
                      </label>
                      <label htmlFor="e7" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Round/square" id="e7" checked={edgeSelected === 'Round/square'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Round/square</p>
                      </label>
                      <label htmlFor="e8" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Bevel edge" id="e8" checked={edgeSelected === 'Bevel edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Bevel edge</p>
                      </label>
                      <label htmlFor="e9" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Slit edge" id="e9" checked={edgeSelected === 'Slit edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Slit edge</p>
                      </label>
                      <label htmlFor="e10" className='radio-option rounded-top'>
                        <input type="radio" name="Edge" value="Custom edge" id="e10" checked={edgeSelected === 'Custom edge'} onChange={handleEdgeChange} className='radio-btn'/>
                        <p className='quote-calculator-text-styling center-text'>Custom edge</p>
                      </label>
                    </div>
                    </div>
                  </div>
                </div>
                <div className='q-padding-bottom-50 quote-bottom-border-splitter'>
                  <p className='quote-calculator-text-styling q-padding-top-50 q-margin-bottom-23'>Enter details:</p>
                  
                  <div className='enter-details-flex'>
                    <div className='quote-textbox-container'>
                      <div className='quote-textbox-description'>
                        <p className='quote-calculator-text-styling color-57606D'>Gauge</p>
                      </div>
                      <div className='quote-textbox-content'>
                        <input type="text" placeholder='.0015 - .312' name='Gauge' value={gauge} onChange={(event) => setGaugeHandler(event)} className='textbox-styling quote-calculator-text-styling make-width-80 color-57606D'/>
                      </div>
                    </div>
                    <div className='quote-textbox-container'>
                      <div className='quote-textbox-description'>
                        <p className='quote-calculator-text-styling color-57606D'>Finish</p>
                      </div>
                      <div className='quote-textbox-content'>
                        <input type="text" placeholder='Finish' name='Finish' value={finish} onChange={(event) => setFinish(event.target.value)} className='textbox-styling quote-calculator-text-styling make-width-80 color-57606D'/>
                      </div>
                    </div>
                    <div className='quote-textbox-container'>
                      <div className='quote-textbox-description'>
                        <p className='quote-calculator-text-styling color-57606D'>Coil OD</p>
                      </div>
                      <div className='quote-textbox-content'>
                        <input type="number" placeholder='“' name='CoilOD' value={coilOd} onChange={(event) => setCoilOdHandler(event)} className='textbox-styling quote-calculator-text-styling make-width-80 color-57606D'/>
                      </div>
                    </div>
                    <div className='quote-textbox-container hide-this'>
                      HIDE
                    </div>
                  
                    <div className='quote-textbox-container'>
                      <div className='quote-textbox-description'>
                        <p className='quote-calculator-text-styling color-57606D'>Width</p>
                      </div>
                      <div className='quote-textbox-content'>
                        <input type="number" placeholder='.250 - 48"' name='Width' value={width} onChange={(event) => setWidthHandler(event)} className='textbox-styling quote-calculator-text-styling make-width-80 color-57606D'/>
                      </div>
                    </div>
                    <div className='quote-textbox-container'>
                      <div className='quote-textbox-description'>
                        <p className='quote-calculator-text-styling color-57606D'>Coil ID</p>
                      </div>
                      <div className='quote-textbox-content'>
                        <input type="number" placeholder='“' name='CoilID' value={coilId} onChange={(event) => setCoilIdHandler(event)} className='textbox-styling quote-calculator-text-styling make-width-80 color-57606D'/>
                      </div>
                    </div>
                    <div className='quote-textbox-container'>
                      <div className='quote-textbox-description'>
                        <p className='quote-calculator-text-styling color-57606D'>Quantity</p>
                      </div>
                      <div className='quote-textbox-content'>
                        <input type="number" placeholder='Lbs' name='Quantity' value={quantity} onChange={(event) => setQuantityHandler(event)} className='textbox-styling quote-calculator-text-styling make-width-80 color-57606D'/>
                      </div>
                    </div>
                    <div className='quote-textbox-container hide-this'>
                      HIDE
                    </div>
                  </div>



                </div>
                <div className='q-padding-bottom-50'>
                  <p className='quote-calculator-text-styling q-padding-top-50 q-margin-bottom-23'>Enter contact information:</p>
                  <div className="contact-row">  
                    <div className='grid-2-by-2 make-it-50'>
                      <input className='contact-textbox' type="text" name='Name' value={name1} onChange={(event) => setName1(event.target.value)} placeholder='Your Name' />
                      <input className='contact-textbox' type="text" name='Email' value={email1} onChange={(event) => setEmail1(event.target.value)} placeholder='Your Email' />
                      <input className='contact-textbox' type="text" name='Telephone' value={telephone1} onChange={(event) => setTelephone1(event.target.value)} placeholder='Telephone' />
                      <input className='contact-textbox' type="text" name='Company' value={compnay1} onChange={(event) => setCompany1(event.target.value)} placeholder='Company (Optional)' />
                    </div>
                    <div className='make-it-50'>
                      <textarea className='contact-textarea' name="message" value={message1} onChange={(event) => setMessage1(event.target.value)} placeholder='Enter your message here' ></textarea>
                    </div>
                  </div>
                </div>
                <div className="quote-button-container">
                  <button type="submit" className='quote-btn-bue'>Get a quote</button>
                  <button type="button" onClick={ClearRegularOrder} className='quote-btn-white'>Clear</button>
                </div>
              </form>
            </div>
            {/* Toll calculator */}
            <div className={`regular-calculator-main-container ${pickedCalculator === 'toll' ? "show-calculator" : "hide-calculator"}`}>
              <div className="regular-calculator-content-container noSelect q-padding-bottom-50">
              <form className="regular-calculator-content-container" onSubmit={handleSubmitToll}>
                <p className='quote-calculator-text-styling q-padding-top-50 q-margin-bottom-23'>Please select the services for which you would like to receive a quote:</p>
                <div className='checkbox-grid q-padding-bottom-50 quote-bottom-border-splitter'>
                  <label className={`checkbox-label ${checkboxValues.slittingCB ? "checkbox-label-checked" : ""}`}>
                    <p className={`quote-calculator-text-styling ${checkboxValues.slittingCB ? "checked-text-color" : ""}`}>Slitting</p>
                    <input type="checkbox" name="slittingCB" value="Slitting" onChange={handleCheckboxChange} checked={checkboxValues.slittingCB} className='checkbox-input' />
                    <img src={CircleCheckBox} alt="" className={`${checkboxValues.slittingCB ? "hide-circle" : "show-circle"}`} />
                    <img src={CheckboxChecked} alt="" className={`${checkboxValues.slittingCB ? "show-circle" : "hide-circle"}`} />
                  </label>
                  <label className={`checkbox-label ${checkboxValues.rollingCB ? "checkbox-label-checked" : ""}`}>
                    <p className={`quote-calculator-text-styling ${checkboxValues.rollingCB ? "checked-text-color" : ""}`}>Rolling</p>
                    <input type="checkbox" name="rollingCB" value="Rolling" onChange={handleCheckboxChange} checked={checkboxValues.rollingCB} className='checkbox-input' />
                    <img src={CircleCheckBox} alt="" className={`${checkboxValues.rollingCB ? "hide-circle" : "show-circle"}`} />
                    <img src={CheckboxChecked} alt="" className={`${checkboxValues.rollingCB ? "show-circle" : "hide-circle"}`} />
                  </label>
                  <label className={`checkbox-label ${checkboxValues.annealingCB ? "checkbox-label-checked" : ""}`}>
                    <p className={`quote-calculator-text-styling ${checkboxValues.annealingCB ? "checked-text-color" : ""}`}>Annealing</p>
                    <input type="checkbox" name="annealingCB" value="Annealing" onChange={handleCheckboxChange} checked={checkboxValues.annealingCB} className='checkbox-input' />
                    <img src={CircleCheckBox} alt="" className={`${checkboxValues.annealingCB ? "hide-circle" : "show-circle"}`} />
                    <img src={CheckboxChecked} alt="" className={`${checkboxValues.annealingCB ? "show-circle" : "hide-circle"}`} />
                  </label>
                  <label className={`checkbox-label ${checkboxValues.edgingCB ? "checkbox-label-checked" : ""}`}>
                    <p className={`quote-calculator-text-styling ${checkboxValues.edgingCB ? "checked-text-color" : ""}`}>Edging</p>
                    <input type="checkbox" name="edgingCB" value="Edging" onChange={handleCheckboxChange} checked={checkboxValues.edgingCB} className='checkbox-input' />
                    <img src={CircleCheckBox} alt="" className={`${checkboxValues.edgingCB ? "hide-circle" : "show-circle"}`} />
                    <img src={CheckboxChecked} alt="" className={`${checkboxValues.edgingCB ? "show-circle" : "hide-circle"}`} />
                  </label>
                  <label className={`checkbox-label ${checkboxValues.cutToLengthCB ? "checkbox-label-checked" : ""}`}>
                    <p className={`quote-calculator-text-styling ${checkboxValues.cutToLengthCB ? "checked-text-color" : ""}`}>Cut to Length</p>
                    <input type="checkbox" name="cutToLengthCB" value="Cut to Length" onChange={handleCheckboxChange} checked={checkboxValues.cutToLengthCB} className='checkbox-input' />
                    <img src={CircleCheckBox} alt="" className={`${checkboxValues.cutToLengthCB ? "hide-circle" : "show-circle"}`} />
                    <img src={CheckboxChecked} alt="" className={`${checkboxValues.cutToLengthCB ? "show-circle" : "hide-circle"}`} />
                  </label>
                  <label className={`checkbox-label ${checkboxValues.labTestingCB ? "checkbox-label-checked" : ""}`}>
                    <p className={`quote-calculator-text-styling ${checkboxValues.labTestingCB ? "checked-text-color" : ""}`}>Lab Testing</p>
                    <input type="checkbox" name="labTestingCB" value="Lab Testing" onChange={handleCheckboxChange} checked={checkboxValues.labTestingCB} className='checkbox-input' />
                    <img src={CircleCheckBox} alt="" className={`${checkboxValues.labTestingCB ? "hide-circle" : "show-circle"}`} />
                    <img src={CheckboxChecked} alt="" className={`${checkboxValues.labTestingCB ? "show-circle" : "hide-circle"}`} />
                  </label>
                  <label className={`checkbox-label ${checkboxValues.packagingCB ? "checkbox-label-checked" : ""}`}>
                    <p className={`quote-calculator-text-styling ${checkboxValues.packagingCB ? "checked-text-color" : ""}`}>Packaging</p>
                    <input type="checkbox" name="packagingCB" value="Packaging" onChange={handleCheckboxChange} checked={checkboxValues.packagingCB} className='checkbox-input' />
                    <img src={CircleCheckBox} alt="" className={`${checkboxValues.packagingCB ? "hide-circle" : "show-circle"}`} />
                    <img src={CheckboxChecked} alt="" className={`${checkboxValues.packagingCB ? "show-circle" : "hide-circle"}`} />
                  </label>
                </div>
                <div className='q-padding-bottom-50'>
                  <p className='quote-calculator-text-styling q-padding-top-50 q-margin-bottom-23'>Enter contact and additional toll process information:</p>
                  <div className="contact-row">  
                    <div className='grid-2-by-2 make-it-50'>
                      <input className='contact-textbox' type="text" name='Name' value={name2} onChange={(event) => setName2(event.target.value)} placeholder='Your Name' />
                      <input className='contact-textbox' type="text" name='Email' value={email2} onChange={(event) => setEmail2(event.target.value)} placeholder='Your Email' />
                      <input className='contact-textbox' type="text" name='Telephone' value={telephone2} onChange={(event) => setTelephone2(event.target.value)} placeholder='Telephone' />
                      <input className='contact-textbox' type="text" name='Company' value={compnay2} onChange={(event) => setCompany2(event.target.value)} placeholder='Company (Optional)' />
                    </div>
                    <div className='make-it-50'>
                      <textarea className='contact-textarea' name="message" value={message2} onChange={(event) => setMessage2(event.target.value)} placeholder='Enter your message here' ></textarea>
                    </div>
                  </div>
                </div>
                <div className="quote-button-container">
                  <button type="submit" className='quote-btn-bue'>Get a quote</button>
                  <button type="button" onClick={ClearTollProces} className='quote-btn-white'>Clear</button>
                </div>
              </form>
              </div>
            </div>
            <div className="quote-info-row q-padding-top-35 q-padding-bottom-150">
              <div className="quote-info-row-width-fix">
                <div className='quote-info-single-row'>
                  <span>Email:</span>
                  <p>&nbsp;info@tandemmetals.com</p>
                </div>
                <div className='quote-info-single-row'>
                  <span>Phone:</span>
                  <p>&nbsp;708-235- 8912</p>
                </div>
                <div className='quote-info-single-row'>
                  <span>Business hours:</span>
                  <p>&nbsp;8:00 AM - 5:00 PM</p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default QuoteForm