import React from 'react'

import RoundEdge from '../../assets/svg/edges/Rounded-edge.svg';
import MillEdge from '../../assets/svg/edges/Mill-edge.svg';
import SlitEdge from '../../assets/svg/edges/Slit-Edge.svg';
import RoundCornerEdge from '../../assets/svg/edges/Round-Corner-Edge.svg';
import DeburredEdge from '../../assets/svg/edges/Deburred-Edge.svg';
import SquareEdge from '../../assets/svg/edges/Square-Edge.svg';
import SpecialEdge from '../../assets/svg/edges/Special-Edge.svg';

const Edging = () => {
  return (

  <>
    <div className="single-service-background main-top-padding">
      <div className="single-service-container service-bigger-bottom-padding">
        <h1 className='main-service-heading'>Edging</h1>
        <p className='main-paragraph-styling bold-text service-top-margin service-smaller-bottom-margin make-50-width paragraph-right-margin'>Many customers take advantage of our edging abilities such as:</p>
        <div className="two-paragraphs-row">
            <div className='make-50-width main-paragraph-styling paragraph-right-margin service-bottom-margin-egde-ul ul-list-grid'>
              <p>•</p><p>Extremely tight width tolerances.</p>
              <p>•</p><p>Special flatness requirements.</p>
              <p>•</p><p>Uniform surface finish for stamping and plating.</p>
              <p>•</p><p>End products that requires a safe round edge for handling.</p>
            </div>
          <p className='main-paragraph-styling make-50-width'>Tandem Metals offers full blown reversing mill rolling of ferrous and non-ferrous strip stock. Our experienced staff has been trained to handle any request you have.</p>
        </div>
      </div>
    </div>
    <div className="edging-grid-bg-container">
      <div className='temp-container service-bigger-top-padding'>
        <div className="edging-grid-container">
          <div className='edging-grid-cell'>
            <img src={RoundEdge} alt="" className='service-smaller-bottom-margin' />
            <p className='service-smaller-bottom-margin main-paragraph-styling bold-text underline-text'>#1 Round edge</p>
            <p className='main-paragraph-styling service-bottom-margin'>Aproximate radius equal to 1/2 thickness</p>
          </div>
          <div className='edging-grid-cell'>
            <img src={MillEdge} alt="" className='service-smaller-bottom-margin' />
            <p className='service-smaller-bottom-margin main-paragraph-styling bold-text underline-text'>#2 Mill Edge</p>
            <p className='main-paragraph-styling service-bottom-margin'>Hot rolled or cold rolled edge, naturally formed</p>
          </div>
          <div className='edging-grid-cell'>
            <img src={SlitEdge} alt="" className='service-smaller-bottom-margin' />
            <p className='service-smaller-bottom-margin main-paragraph-styling bold-text underline-text'>#3 Slit Edge</p>
            <p className='main-paragraph-styling service-bottom-margin'>Approximately square edge, slitting burr intact</p>
          </div>
          <div className='edging-grid-cell'>
            <img src={RoundCornerEdge} alt="" className='service-smaller-bottom-margin' />
            <p className='service-smaller-bottom-margin main-paragraph-styling bold-text underline-text'>#4 Round-Corner Edge</p>
            <p className='main-paragraph-styling service-bottom-margin'>a.k.a. Safety edge, semi-round corners less radius than #1 Round</p>
          </div>
          <div className='edging-grid-cell'>
            <img src={DeburredEdge} alt="" className='service-smaller-bottom-margin' />
            <p className='service-smaller-bottom-margin main-paragraph-styling bold-text underline-text'>#5 Deburred Edge</p>
            <p className='main-paragraph-styling service-bottom-margin'>A safety edge - No. 3 Edge with the slitting burr rolled over</p>
          </div>
          <div className='edging-grid-cell'>
            <img src={SquareEdge} alt="" className='service-smaller-bottom-margin' />
            <p className='service-smaller-bottom-margin main-paragraph-styling bold-text underline-text'>#6 Square Edge</p>
            <p className='main-paragraph-styling service-bottom-margin'>Square with radial corners, slitting fracture may be visible</p>
          </div>
          <div className='edging-grid-cell'>
            <img src={SpecialEdge} alt="" className='service-smaller-bottom-margin' />
            <p className='service-smaller-bottom-margin main-paragraph-styling bold-text underline-text'>#7 Special Edges</p>
            <p className='main-paragraph-styling service-bottom-margin'>We can produce special edge as needed or required</p>
          </div>
          
        </div>
        <div className='service-top-margin service-bigger-bottom-padding'>
          <span className='main-paragraph-styling '>
            <p className='make-text-inline'>We do metal <b className='blue-text make-text-inline'>edging operations </b></p>
            in accordance with the standards set by the American Iron and Steel Institute (AISI)
          </span>
        </div>
      </div>
    </div>
    <div className="single-service-background">
      <div className="single-service-container service-bigger-top-padding">
        <p className='main-paragraph-styling make-60-width service-bigger-bottom-padding'>Tandem Metals offers full blown reversing mill rolling of ferrous and non-ferrous strip stock. Our experienced staff has been trained to handle any request you have.</p>
        <div className="services-boxes-container service-bottom-margin">
          <div className="services-box">
            <p>Cold Rolled</p>
          </div>
          <div className="services-box">
            <p>Low carbon</p>
          </div>
          <div className="services-box">
            <p>High carbon</p>
          </div>
          <div className="services-box">
            <p>Annealed</p>
          </div>
          <div className="services-box">
            <p>Pre-tempered spring steels</p>
          </div>
          <div className="services-box">
            <p>Coated</p>
          </div>
          <div className="services-box">
            <p>Stainless</p>
          </div>
          <div className="services-box">
            <p>Brass</p>
          </div>
          <div className="services-box">
            <p>Copper</p>
          </div>
          <div className="services-box">
            <p>Bronze</p>
          </div>
          <div className="services-box">
            <p>Titanium</p>
          </div>
          <div className="services-box">
            <p>Phosphor Bronze</p>
          </div>
          <div className="services-box">
            <p>Hastelloy</p>
          </div>
          <div className="services-box">
            <p>Inconel</p>
          </div>
          <div className="services-box">
            <p>Nickel</p>
          </div>
          <div className="services-box">
            <p>Monel</p>
          </div>
        </div>
        <h1 className='main-service-heading service-bigger-top-padding'>Skiving</h1>
        <div className="two-paragraphs-row service-top-margin">
          <p className='main-paragraph-styling make-50-width paragraph-right-margin service-bottom-margin-on-s-screen'>Our Skiving machine produces a round or special shaped edge. Skived edge is produced between .008 and .062 in thickness. Material .375" min to 6" max width. A skived edge is produced by carbide cutting tools. </p>
          <p className='main-paragraph-styling make-50-width'>Depending on the gauge, round edge, square edge, or special shaped edge can be produced. End product can be cut to length or recoiled in either ribbon or oscillated wound.</p>
        </div>
      </div>
    </div>
  </>

  )
}

export default Edging