import React from 'react';
import '../css/SingleSerice.css';



const Slitting = () => {
  return (
    <div className="single-service-background">
      <div className="single-service-container main-top-padding">
        <h1 className='main-service-heading'>Slitting</h1>
        <p className='main-paragraph-styling service-top-margin service-bottom-margin make-50-width'>We supply high quality close tolerance steel coils. Shimless tooling and roller tensioning allow us to produce coils that are uniformly tensioned with minimal burr and flat sidewalls.</p>
        <div className='service-table-heading'>
          <h3>Our slitting capabilities</h3>
        </div>
        <div className="services-table-grid">
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Gauge Range:</p>
            </div>
            <div className="second-in-pair">
              <p>.001” - .250”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Maximum Coil Width</p>
            </div>
            <div className="second-in-pair">
              <p>36”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Maximum Coil OD:</p>
            </div>
            <div className="second-in-pair">
              <p>52”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Maximum Coil Weight:</p>
            </div>
            <div className="second-in-pair">
              <p>15,000 lbs</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Uncoiler ID:</p>
            </div>
            <div className="second-in-pair">
              <p>6” to 20”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Recoiler ID:</p>
            </div>
            <div className="second-in-pair">
              <p>6” and 20”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Maximum Slit Width:</p>
            </div>
            <div className="second-in-pair">
              <p>48”</p>
            </div>
          </div>
          <div className="table-pair">
            <div className="first-in-pair">
              <p>Minimum Slit Width:</p>
            </div>
            <div className="second-in-pair">
              <p>.032”</p>
            </div>
          </div>
        </div>
        <p className='main-paragraph-styling service-top-margin service-bottom-margin make-50-width'>Quality control procedures assure that the following meet our customer’s specifications:</p>
        <div className="services-boxes-container">
          <div className="services-box">
            <p>Thickness</p>
          </div>
          <div className="services-box">
            <p>Slit edge quality</p>
          </div>
          <div className="services-box">
            <p>Slit Width</p>
          </div>
          <div className="services-box">
            <p>Surface Condition Hardness</p>
          </div>
          <div className="services-box">
            <p>Strip Camber</p>
          </div>
          <div className="services-box">
            <p>Mechanical Properties</p>
          </div>
          <div className="services-box">
            <p>Chemical Properties</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slitting