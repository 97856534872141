import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import PhoneIcon from "../assets/svg/phone-dark.svg";
import LogoLight from "../assets/images/Logo-Dark.png";
import HamburgerClose from "../assets/svg/Hamburger-Close.svg";
import HamburgerDark from "../assets/svg/Hamburger-menu.svg";


import './css/NavbarDark.css';

const Navbar = () => {

  const location = useLocation();
  const currentPath = location.pathname;

  const[click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  click?document.body.style.overflow = "hidden":document.body.style.overflow = "auto";

  function scrollToElementById(id) {
    const element = document.getElementById(id);
    setClick(false);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <div className="navbar-overflow">
      <div className={`navbar-dark ${scrolled ? 'navbar-dark-scrolled' : ''}`} id="navbar-height-dark">
        <Link to='/' class={`navbar-name ${scrolled ? 'navbar-name-scrolled' : ''}`}>
          <img src={LogoLight} alt="" />
        </Link>
        <div className={`nav-links ${click ? "show-nav-link" : "" } `}>
          <div className="hamburger-width-container">
            <button className='hamburger-close-button'>
              <img src={HamburgerClose} alt="X" onClick={handleClick}/>
            </button>
            <Link to='/services' activeStyle className={`link-text-style-dark b-top b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className={`nav-text-dark ${currentPath==='/services' ? 'active-navbar-text' : ""}`}>Services</p></Link>
            <Link to='/products' activeStyle className={`link-text-style-dark b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className={`nav-text-dark ${currentPath==='/products' ? 'active-navbar-text' : ""}`}>Products</p></Link>
            {/* <button to='/' onClick={() => scrollToElementById('calculatorID')} className={`link-text-style-dark b-bottom remove-button-style ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className='nav-text-dark'>Tehnical&nbsp;Data</p></button> */}
            <Link to='/' state={{ goToPiw: true }} activeStyle className={`link-text-style-dark b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className='nav-text-dark'>Tehnical&nbsp;Data</p></Link>
            <Link to='/about' activeStyle className={`link-text-style-dark b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className={`nav-text-dark ${currentPath==='/about' ? 'active-navbar-text' : ""}`}>About&nbsp;Us</p></Link>
            <Link to='/contact' activeStyle className={`link-text-style-dark b-bottom ${scrolled ? 'scrolled-navbar-text' : ''}`}><p className={`nav-text-dark ${currentPath==='/contact' ? 'active-navbar-text' : ""}`}>Contact&nbsp;Us</p></Link>
            <Link to="/quote" className={`nav-btn nav-btn-margin ${scrolled ? 'nav-btn-scrolled' : ''}`}>
              Get&nbsp;a&nbsp;quote
            </Link>
            <button className='b-top-light close-button' onClick={handleClick}>
              <p className='nav-text'>Close menu</p> 
            </button>
            <div className="number-flex">
              <img src={PhoneIcon} alt="Phone" height={16} width={16} />
              <span className={`navbar-number-dark ${scrolled ? 'navbar-number-scrolled' : ''}`}>
                708-235-8912
              </span>
            </div>

          </div>
        </div>
        <button className={`hamburger-menu-button ${scrolled ? 'navbar-menu-button-scrolled' : ''}`} onClick={handleClick}>
          <img src={HamburgerDark} alt="MENU"/>
        </button>
      </div>
    </div>
    <div className="dummy-navbar-margin"></div>
    </>
  )
}

export default Navbar