import React from 'react'

const Annealing = () => {
  return (
    <div className="single-service-background">
      <div className="single-service-container main-top-padding">
        <h1 className='main-service-heading'>Annealing</h1>
        <div className="two-paragraphs-row service-top-margin service-bottom-margin">
          <p className='main-paragraph-styling make-50-width paragraph-right-margin service-bottom-margin-on-s-screen'>Annealing is a heat treatment process which alters the metallurgical structure of a material to change its mechanical or electrical properties. Typically, this process is used to decrease metal crack sensitivity of aged material that needs to be returned to a weldable state. Solution Annealing will affect secondary carbides precipitated during extended high temperature service by increasing ambient temperature ductility and fracture toughness. This process involves air quenching post heat application.</p>
          <div className="two-paragraphs-in-column make-50-width">
          <p className='main-paragraph-styling service-smaller-bottom-margin'>We anneal light gauge precision strip steel in a controlled hydrogen atmosphere furnace. This furnace can anneal anything from <b className='bold-text'>low carbon steels like 1008 &1010 all the way to high carbon steels 1050,1075, and 1095 </b>in any gauge.</p>
          <p className='main-paragraph-styling'>We also offer strand annealing services for light gauge precision strip steel. As our continuous annealing furnaces are ideally suited for copper, copper alloy, nickel, nickel chrome, titanium, stainless steel and refractory metals.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Annealing