import React from 'react';
import NavbarDark from '../components/NavbarDark';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';


const About = () => {
  return (
    <>
      <NavbarDark />
      <AboutUs />
      <Footer />
    </>
  )
}

export default About