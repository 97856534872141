import React, { useState, useRef, forwardRef  } from 'react';
import { Link } from 'react-router-dom';
import './css/ServicesTop.css';
import UpArrow from '../assets/svg/right-arrow.svg';

import ServicesMetalSheets from '../assets/images/services-metal-sheets.png';
import Industries from '../assets/images/Industries.png';

const ServicesTop = () => {

  const [showExtraText, setShowExtraText] = useState(false);
  const [buttonText, setButtonText] = useState('Read more');

  function buttonHandler(){
    setShowExtraText(
      (prev) => !prev
    );
    if(buttonText === 'Read less'){
      setButtonText('Read more');
    }else{
      setButtonText('Read less');
    }
  }

  const topRef = useRef(null);

  return (
    <>
      <span id='topId' ref={topRef}></span>
      <div className='services-landing-bg-container'>
        <div className="services-main-container">
          <div className="services-landing-flex">
            <div className='services-first-column'>
              <h4 className='margin-bottom-40'>SERVICES</h4>
              <img src={ServicesMetalSheets} alt="MetalSheets" className='services-img-small-screen'/>
              <h1 className='main-service-heading margin-bottom-84'>We provide the processing services you need</h1>
              <div className="read-flex">
                <div className={`read-more-container ${showExtraText ? "hide-first-text" : ""}`}>
                  <p className='main-paragraph-styling margin-bottom-40'>We like to be a one-stop shop providing all the flat rolled processing services you could possibly need, available in aluminum, carbon and stainless steel. We cover the complete gauge range from 0.0015" to 0.250" with coils up to 48" wide. We also work to exacting tolerances and optimum flatness and squareness.</p>
                  <p className='main-paragraph-styling margin-bottom-32'>ISO 9001:2000 certified custom material skiving and slitting services. Capabilities to slit gauges from 0.001 in. to 0.25 in., roll widths from 0.032 in. to 48 in., gauge tolerances down to 0.0005 in., width tolerances down to 0.005 in., core sizes from 6 in. to 20 in. & maximum coil weights to 15,000 lbs. Materials slit include low carbon & high carbon steels, coated metals, stainless steels, red metals & specialty alloys.</p>
                  <span className='main-paragraph-styling'>Our business also consists of <span className='bold-text'>toll processing!&nbsp;&nbsp;</span></span>
                  <button onClick={buttonHandler} className='more-text-button margin-bottom-63'>
                    Read more
                    <img src={UpArrow} alt="Arrow" className='arrow-button-more-text' />
                  </button>
                </div>
                <div className={`read-less-container ${!showExtraText ? "" : "show-second-text"}`}>
                  <p className='main-paragraph-styling extra-text-style margin-bottom-40'>Our business also consists of toll processing, doing professional services for our clients where we use their material and supplies!</p>
                  <p className='main-paragraph-styling extra-text-style margin-bottom-40'>Simply put, toll processing involves fabricating your purchased metals according to your specifications — no more, no less. It may involve, slitting, edging, rolling, annealing, oscillate winding. Think of it as a way of simplifying your business’ value chain. It takes a section of the production process and hands it off to a trusted expert.</p>
                  <p className='main-paragraph-styling extra-text-style margin-bottom-40'>• Slitting min / max width .125" - 36"<br/>• Rolling: min/max gauge .0015" - .250"<br/>• (Rolling .005 to .600 for temper) ????</p>
                  <br />
                  <button onClick={buttonHandler} className='more-text-button'>
                    About services
                    <img src={UpArrow} alt="Arrow" className={`arrow-button-more-text rotate-arrow ${showExtraText ? "expanded" : ""}`} />
                  </button>
                </div>
              </div>
              <div className="service-buttons-container margin-bottom-140">
                <Link to="/products" className="services-btn-bue">View&nbsp;our&nbsp;Products</Link>
                <Link to="/contact" className="services-btn-white">Contact&nbsp;us</Link>
              </div>
            </div>
            <div className='services-second-column'>
              <img src={ServicesMetalSheets} alt="MetalSheets" className='services-img-big-screen'/>
            </div>
          </div>
        </div>
      </div>
      <div className="industries-bg-container">
        <div className="industries-main-container">
          <div className="industries-flex industries-flex margin-bottom-120 padding-top-100">
            <div className='industries-first-column'>
              <h1 className='main-service-heading industries-text-color margin-bottom-40'>Industries that we serve:</h1>
              <p className='main-paragraph-styling industries-text-color margin-bottom-32'>Our business also consists of toll processing, doing professional services for our clients where we use their material and supplies!</p>
              <Link to="/quote" className='remove-underline'><span className='main-paragraph-styling link-blue-style'>Become a part of our family and get a quote Today!</span></Link>
            </div>
            <div className='industries-second-column top-margin-under-800'>
              <img src={Industries} alt="Industries" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesTop