import React from 'react'
import ServicesTop from '../components/ServicesTop';
import ServicesBottom from '../components/ServicesBottom';
import Footer from '../components/Footer';
import NavbarDark from '../components/NavbarDark';

const Services = () => {
  return (
    <>
      <NavbarDark />
      <ServicesTop />
      <ServicesBottom />
      <Footer />
    </>
  )
}

export default Services