import "./index.css";
import Home from './routes/Home';
import About from './routes/About';
import Products from "./routes/Products";
import Services from "./routes/Services";
import Contact from "./routes/Contact";
import Quote from "./routes/Quote";
import FavIcon from "./assets/favicon.ico";

import { Route, Routes } from 'react-router-dom';
import { Helmet } from "react-helmet";


function App() {
  return (
    <>
      <Helmet>
        <title>Tandem Metals</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Discover high-quality metal products and solutions at TandemMetals. From durable alloys to precision components, trust us for all your metal needs." />
        <meta name="keywords" content="metal products, metal solutions, alloys, precision components, TandemMetals" />v
        <link rel="icon" href={FavIcon} />
        <meta name="theme-color" content="#293447" />
        <title>Tandem Metals</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}

export default App;
