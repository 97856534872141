import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './css/Services.css';
import './css/HorizontalMenu.css';

import Slitting from "../assets/images/Slitting.jpg";
import Rolling from "../assets/images/Rolling.jpg";
import CutToLenght from "../assets/images/Cut-to-Lenght.jpg";
import AnalyticalLaboratory from "../assets/images/Analytical-laboratory.jpg";
import Annealing from "../assets/images/Annealing.jpg";
import Edging from "../assets/images/Edging.jpg";
import TollProcessing from "../assets/images/Toll-Processing.jpg";
import Packaging from "../assets/images/Packaging.jpg";

import Leftarrow from "../assets/svg/left-arrow.svg";
import RightArrow from "../assets/svg/right-arrow.svg";
import RightTriangle from "../assets/svg/right-facing-triangle.svg";

import SlittingComponent from "./ServicesComponents/Slitting";
import RollingComponent from "./ServicesComponents/Rolling";
import EdgingComponent from "./ServicesComponents/Edging";
import AnnealingComponent from "./ServicesComponents/Annealing";
import CutToLenghtComponent from "./ServicesComponents/CutToLenght";
import AnalyticalLaboratoryComponent from "./ServicesComponents/AnalyticalLaboratory";
import PackagingComponent from "./ServicesComponents/Packaging";
import TollProcessingComponent from "./ServicesComponents/TollProcessing";

import UpTriangle from "../assets/svg/up-facing-triangle.svg"

const ServicesBottom = () => {

  const location = useLocation()
  const firstServiceToShow = location.state && location.state.serviceToShow
    ? location.state.serviceToShow
    : 'SlittingComponent'
    

    const initialScroolToTop = location.state && location.state.serviceToShow
    ? 'bottomId'
    : 'topId';

    const [showComponent, setShowComponent] = useState(firstServiceToShow);
    
    


  const menuRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  let isDragging = useRef(false); // Flag to track dragging state

  const handleMouseDown = (event) => {
    const initialMousePosition = event.clientX;
    const initialScrollPosition = menuRef.current.scrollLeft;
  
    const handleMouseMove = (event) => {
      isDragging.current = true;
      const distance = event.clientX - initialMousePosition;
      menuRef.current.scrollLeft = initialScrollPosition - distance;
    };
  
    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      
      setTimeout(() => {
        isDragging.current = false;
      }, 2);
    };
  
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const [isScrolledToStart, setIsScrolledToStart] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);


  const handleScrollLeft = () => {
    const container = menuRef.current;
    const item = container.querySelector('div');
    const itemWidth = item.offsetWidth + parseInt(getComputedStyle(item).marginRight);
    container.scrollTo({
      left: container.scrollLeft - itemWidth,
      behavior: 'smooth',
    });
  };

  const handleScrollRight = () => {
    const container = menuRef.current;
    const item = container.querySelector('div');
    const itemWidth = item.offsetWidth + parseInt(getComputedStyle(item).marginRight);
    container.scrollTo({
      left: container.scrollLeft + itemWidth,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setIsScrolledToStart(menuRef.current.scrollLeft === 0);
    setIsScrolledToEnd(Math.ceil(menuRef.current.scrollLeft + menuRef.current.clientWidth) >= Math.floor(menuRef.current.scrollWidth));
    const handleScroll = () => {
      setIsScrolledToStart(menuRef.current.scrollLeft === 0);
      setIsScrolledToEnd(Math.ceil(menuRef.current.scrollLeft + menuRef.current.clientWidth) >= Math.floor(menuRef.current.scrollWidth));
    };

    menuRef.current.addEventListener('scroll', handleScroll);
    return () => {
      if(menuRef.current !== null)
      menuRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // firstServiceToShow
  useEffect(() => {
    setShowComponent(firstServiceToShow);
    const clickedItem = document.getElementById(firstServiceToShow);
    
    const containerWidth = menuRef.current.offsetWidth;
    const containerScrollLeft = menuRef.current.scrollLeft;
    const itemOffsetLeft = clickedItem.offsetLeft;
    const itemWidth = clickedItem.offsetWidth;


    let instantScroll = 0;
    let numberOfItems = 0;
    if(firstServiceToShow === 'SlittingComponent') numberOfItems = 1;
    if(firstServiceToShow === 'RollingComponent') numberOfItems = 2;
    if(firstServiceToShow === 'EdgingComponent') numberOfItems = 3;
    if(firstServiceToShow === 'AnnealingComponent') numberOfItems = 4;
    if(firstServiceToShow === 'CutToLenghtComponent') numberOfItems = 5;
    if(firstServiceToShow === 'AnalyticalLaboratoryComponent') numberOfItems = 6;
    if(firstServiceToShow === 'PackagingComponent') numberOfItems = 7;
    if(firstServiceToShow === 'TollProcessingComponent') numberOfItems = 8;
    let scrollPositionBeforeSmoothScrollTemp = 0;
    if(numberOfItems <= 4){
      const instantScrollItem = document.getElementById('TollProcessingComponent');
      const instantScrollItemOffestLeft = instantScrollItem.offsetLeft;
      instantScroll = instantScrollItemOffestLeft - (containerWidth - itemWidth) / 2;
      console.log(instantScroll);
    }

    const scrollPosition = itemOffsetLeft - (containerWidth - itemWidth) / 2;

    // console.log("containerWidth:" + containerWidth + " itemOffsetLeft:" + itemOffsetLeft + " itemWidth:" + itemWidth);
    // console.log("num:" + numberOfItems + " scrollPos:" + scrollPosition);
    console.log("Instant scroll: " + instantScroll + " SmoothScroll: " + scrollPosition + " Number of item: ");


    menuRef.current.scrollTo({
      left: instantScroll,
      behavior: 'instant',
    });

    menuRef.current.scrollTo({
      left: scrollPosition,
      behavior: 'smooth',
    });
  }, []);

    // Initial scroll
    useEffect(() => {
      const variable = 20;
      let ref = document.getElementById(initialScroolToTop);
      ref.scrollIntoView({ behavior: 'instant' });
    }, [initialScroolToTop]);

  // Handle item click
  const handleItemClick = (event, pickedService) => {
    if (isDragging.current === false) {
      setShowComponent(pickedService);
      const clickedItem = document.getElementById(pickedService);
      
      const containerWidth = menuRef.current.offsetWidth;
      const containerScrollLeft = menuRef.current.scrollLeft;
      const itemOffsetLeft = clickedItem.offsetLeft;
      const itemWidth = clickedItem.offsetWidth;
      
      const scrollPosition = itemOffsetLeft - (containerWidth - itemWidth) / 2;
      // console.log("scrollPos:" + scrollPosition);
      menuRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  function scrollToElementById(id) {
    const navbarheight = document.getElementById('navbar-height-dark').offsetHeight;
    const element = document.getElementById(id).offsetTop;
    if (element) {
      const scrollAmmount = element - navbarheight + 10;
      console.log(element + " HELLO " + navbarheight);
      window.scrollTo({
        top : scrollAmmount,
        behavior : 'instant',
      });
    }
    else if(id === 'navbar-height-dark'){
      window.scrollTo({
        top : 0,
        behavior : 'smooth',
      });
    }
  }


  return (
    <>
    <span id='bottomId'></span>
      <div className='height-top-140'></div>
        <div className='hide-horizontal-scroll-overflow'>
          <div ref={menuRef} onMouseDown={handleMouseDown} className='horizontal-rewrite-container'>
            <div id='SlittingComponent' style={{ display: 'inline-block' }} className='horizontal-single-item hor-item-first remove-blue-highlight' onClick={(event) => handleItemClick(event, 'SlittingComponent')}>
              <img src={Slitting} alt="Slitting" className='hor-image remove-blue-highlight'/>
              <div className='btn-text-container'>
                <h5>Slitting</h5>
                <p>Slitting metal sheets</p>
              </div>
              <div className={`btn-box ${showComponent==='SlittingComponent' ? "btn-box-selected" : ""}`}>
                <img src={RightTriangle} alt="" />
              </div>
            </div>
            <div id='RollingComponent' style={{ display: 'inline-block' }} className='horizontal-single-item remove-blue-highlight' onClick={(event) => handleItemClick(event, 'RollingComponent')}>
              <img src={Rolling} alt="Rolling" className='hor-image remove-blue-highlight'/>
              <div className='btn-text-container'>
                <h5>Rolling</h5>
                <p>Cold Rolling And Reduction</p>
              </div>
              <div className={`btn-box ${showComponent==='RollingComponent' ? "btn-box-selected" : ""}`}>
                <img src={RightTriangle} alt="" />
              </div>
            </div>
            <div id='EdgingComponent' style={{ display: 'inline-block' }} className='horizontal-single-item remove-blue-highlight' onClick={(event) => handleItemClick(event, 'EdgingComponent')}>
              <img src={Edging} alt="Edging" className='hor-image remove-blue-highlight'/>
              <div className='btn-text-container'>
                <h5>Edge conditioning</h5>
                <p>Edge to your requirements</p>
              </div>
              <div className={`btn-box ${showComponent==='EdgingComponent' ? "btn-box-selected" : ""}`}>
                <img src={RightTriangle} alt="" />
              </div>
            </div>
            <div id='AnnealingComponent' style={{ display: 'inline-block' }} className='horizontal-single-item remove-blue-highlight' onClick={(event) => handleItemClick(event, 'AnnealingComponent')}>
              <img src={Annealing} alt="Annealing" className='hor-image remove-blue-highlight'/>
              <div className='btn-text-container'>
                <h5>Annealing</h5>
                <p>Heat treatment process</p>
              </div>
              <div className={`btn-box ${showComponent==='AnnealingComponent' ? "btn-box-selected" : ""}`}>
                <img src={RightTriangle} alt="" />
              </div>
            </div>
            <div id='CutToLenghtComponent' style={{ display: 'inline-block' }} className='horizontal-single-item remove-blue-highlight' onClick={(event) => handleItemClick(event, 'CutToLenghtComponent')}>
              <img src={CutToLenght} alt="CutToLenght" className='hor-image remove-blue-highlight'/>
              <div className='btn-text-container'>
                <h5>Cut To Length</h5>
                <p>Cutting in any dimension</p>
              </div>
              <div className={`btn-box ${showComponent==='CutToLenghtComponent' ? "btn-box-selected" : ""}`}>
                <img src={RightTriangle} alt="" />
              </div>
            </div>
            <div id='AnalyticalLaboratoryComponent' style={{ display: 'inline-block' }} className='horizontal-single-item remove-blue-highlight' onClick={(event) => handleItemClick(event, 'AnalyticalLaboratoryComponent')}>
              <img src={AnalyticalLaboratory} alt="AnalyticalLaboratory" className='hor-image remove-blue-highlight'/>
              <div className='btn-text-container'>
                <h5>Analytical Laboratory</h5>
                <p>According to your specs</p>
              </div>
              <div className={`btn-box ${showComponent==='AnalyticalLaboratoryComponent' ? "btn-box-selected" : ""}`}>
                <img src={RightTriangle} alt="" />
              </div>
            </div>
            <div id='PackagingComponent' style={{ display: 'inline-block' }} className='horizontal-single-item remove-blue-highlight' onClick={(event) => handleItemClick(event, 'PackagingComponent')}>
              <img src={Packaging} alt="Packaging" className='hor-image remove-blue-highlight'/>
              <div className='btn-text-container'>
                <h5>Packaging</h5>
                <p>Full laboratory analysis</p>
              </div>
              <div className={`btn-box ${showComponent==='PackagingComponent' ? "btn-box-selected" : ""}`}>
                <img src={RightTriangle} alt="" />
              </div>
            </div>
            <div id='TollProcessingComponent' style={{ display: 'inline-block' }} className='horizontal-single-item hor-item-last remove-blue-highlight' onClick={(event) => handleItemClick(event, 'TollProcessingComponent')}>
              <img src={TollProcessing} alt="TollProcessing" className='hor-image remove-blue-highlight'/>
              <div className='btn-text-container'>
                <h5>Toll Processing</h5>
                <p>Packaging and shipping</p>
              </div>
              <div className={`btn-box ${showComponent==='TollProcessingComponent' ? "btn-box-selected" : ""}`}>
                <img src={RightTriangle} alt="" />
              </div>
            </div>
            <div className="gradient">
            </div>
          </div>
        </div>
      <div className="scroll-buttons-bg-container">
        <div className='scroll-buttons-container'>
          <button onClick={handleScrollLeft} className={`btn-scroll ${isScrolledToStart ? 'btn-scroll-disabled' : ''}`}>
            <img src={Leftarrow} alt="left" className='arrow'/>
          </button>
          <div className='picked-service-indicator-container'>
            <span className={`picked-service-indicator ${showComponent==='SlittingComponent' ? "picked-service-indicator-selected" : ""}`}></span>
            <span className={`picked-service-indicator ${showComponent==='RollingComponent' ? "picked-service-indicator-selected" : ""}`}></span>
            <span className={`picked-service-indicator ${showComponent==='EdgingComponent' ? "picked-service-indicator-selected" : ""}`}></span>
            <span className={`picked-service-indicator ${showComponent==='AnnealingComponent' ? "picked-service-indicator-selected" : ""}`}></span>
            <span className={`picked-service-indicator ${showComponent==='CutToLenghtComponent' ? "picked-service-indicator-selected" : ""}`}></span>
            <span className={`picked-service-indicator ${showComponent==='AnalyticalLaboratoryComponent' ? "picked-service-indicator-selected" : ""}`}></span>
            <span className={`picked-service-indicator ${showComponent==='PackagingComponent' ? "picked-service-indicator-selected" : ""}`}></span>
            <span className={`picked-service-indicator ${showComponent==='TollProcessingComponent' ? "picked-service-indicator-selected" : ""}`}></span>
          </div>
          <button onClick={handleScrollRight} className={`btn-scroll ${isScrolledToEnd ? 'btn-scroll-disabled' : ''}`}>
            <img src={RightArrow} alt="left" className='arrow'/>
          </button>
        </div>
      </div>
      {/* <span id='bottomId'></span> */}
      {showComponent==='SlittingComponent' && <SlittingComponent/>}
      {showComponent==='RollingComponent' && <RollingComponent/>}
      {showComponent==='EdgingComponent' && <EdgingComponent/>}
      {showComponent==='AnnealingComponent' && <AnnealingComponent/>}
      {showComponent==='CutToLenghtComponent' && <CutToLenghtComponent/>}
      {showComponent==='AnalyticalLaboratoryComponent' && <AnalyticalLaboratoryComponent/>}
      {showComponent==='PackagingComponent' && <PackagingComponent/>}
      {showComponent==='TollProcessingComponent' && <TollProcessingComponent/>}
      <div className="btn-on-top-container">
        <button className='services-btn-back-on-top' onClick={() => scrollToElementById('navbar-height-dark')}>
          <p>Back on top</p>
          <img src={UpTriangle} alt="" />
        </button>
      </div>
    </>

  )
}

export default ServicesBottom