import React from 'react';
import { Link } from 'react-router-dom';

const AnalyticalLaboratory = () => {
  return (
    <div className="single-service-background">
      <div className="single-service-container main-top-padding">
        <div className="heading-row-with-buttons">
          <h1 className='main-service-heading'>Analytical Laboratory</h1>
          <div className="service-buttons-container service-bottom-margin buttons-top-margin-s-screen">
            <Link to="/project" className="services-btn-bue">View&nbsp;our&nbsp;Products</Link>
            <Link to="/project" className="services-btn-white">Contact&nbsp;us</Link>
          </div>
        </div>
        <div className="two-paragraphs-row service-bottom-margin">
          <div className='make-50-width'>
            <p className='main-paragraph-styling paragraph-right-margin service-bottom-margin'>Our full analytical laboratory is accredited by ISO 9001-2000 to test for anything from chemical makeup to solderability.</p>
          </div>
        </div>
        <div className="services-boxes-container service-bottom-margin">
          <div className="services-box">
            <p>Chemical and Physical Analysis</p>
          </div>
          <div className="services-box">
            <p>Rockwell hardness Test</p>
          </div>
          <div className="services-box">
            <p>Tensile Strength</p>
          </div>
          <div className="services-box">
            <p>Yield Elongation</p>
          </div>
          <div className="services-box">
            <p>Elongation Test</p>
          </div>
          <div className="services-box">
            <p>Coating X-Ray Test</p>
          </div>
          <div className="services-box">
            <p>Reflow Bake Ovens</p>
          </div>
          <div className="services-box">
            <p>Olson Hardness Test</p>
          </div>
          <div className="services-box">
            <p>Microhardness Test</p>
          </div>
          <div className="services-box">
            <p>Solderability</p>
          </div>
        </div>
      </div>
  </div>
  )
}

export default AnalyticalLaboratory