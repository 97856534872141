import React from 'react';
import { Link } from 'react-router-dom';

const Packaging = () => {
  return (
    <div className="single-service-background main-top-padding">
      <div className="single-service-container">
        <div className="heading-row-with-buttons">
          <h1 className='main-service-heading'>Packaging</h1>
          <div className="service-buttons-container service-bottom-margin buttons-top-margin-s-screen">
            <Link to="/project" className="services-btn-bue">View&nbsp;our&nbsp;Products</Link>
            <Link to="/project" className="services-btn-white">Contact&nbsp;us</Link>
          </div>
        </div>
        <div className="two-paragraphs-row service-bottom-margin">
          <div className='make-50-width'>
            <p className='main-paragraph-styling paragraph-right-margin'>We provide various packaging and shipping methods with the coil eye horizontal (eye to the side) or eye to the sky. We also offer a wide variety of packaging materials including the following:</p>
          </div>
        </div>
        <div className="services-boxes-container service-bottom-margin services-boxes-extra-gap">
          <div className="services-box services-box-thick">
            <p>Chemical and Physical Analysis</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Rockwell hardness Test</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Tensile Strength</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Yield Elongation</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Elongation Test</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Coating X-Ray Test</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Reflow Bake Ovens</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Olson Hardness Test</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Microhardness Test</p>
          </div>
          <div className="services-box services-box-thick">
            <p>Solderability</p>
          </div>
        </div>
      </div>
  </div>
  )
}

export default Packaging