import React from 'react'

const TollProcessing = () => {
  return (
    <div className="single-service-background">
      <div className="single-service-container main-top-padding">
        <h1 className='main-service-heading'>Toll Processing</h1>
        <div className="two-paragraphs-row service-top-margin service-bottom-margin">
          <div className='make-50-width service-bottom-margin-on-s-screen'>
            <p className='main-paragraph-styling paragraph-right-margin service-bottom-margin'>We provide the processing services you need, for the materials you rely on, in the sizes and weights you want.</p>
            <p className='main-paragraph-styling paragraph-right-margin service-bottom-margin'>When it comes to finding the perfect metal for your end-use application, we do more than merely stock the highest quality raw steel. We also provide toll processing (aka toll conversion) for our clients.</p>
            <p className='main-paragraph-styling paragraph-right-margin service-bottom-margin bold-text'>Cold Rolling And Reduction</p>
            <p className='main-paragraph-styling paragraph-right-margin'>Why? We believe that extending this service to you, our valued customers, can help improve your productivity, reduce your overall costs, and prevent you from having to use valuable inventory space. Even better, we can deliver precisely produced products to you at a predictable cost and within a short period of time. We can process your material and hold for JIT (just-in-time) shipments as your production requirements change.</p>
          </div>
          <div className='make-50-width'>
            <p className='main-paragraph-styling paragraph-right-margin service-bottom-margin bold-text'>What Is Toll Processing?</p>
            <p className='main-paragraph-styling paragraph-right-margin service-bottom-margin'>Simply put, toll processing involves fabricating your purchased metals according to your specifications — no more, no less. It may involve, slitting, edging, rolling, annealing, oscillate winding. Think of it as a way of simplifying your business’ value chain. It takes a section of the production process and hands it off to a trusted expert.</p>
            <p className='main-paragraph-styling paragraph-right-margin'>With our toll processing services, you won’t need to concern yourself with investing in new capital projects, storing unfinished goods, or tying up operating capital. You don’t have to deal with highly precise engineering requirements, invest in metal processing equipment, acquire the necessary personnel, or hire technically accomplished employees.</p>
           
          </div>
        </div>
      </div>
    </div>
  )
}
export default TollProcessing