import React from 'react'
import  LandingPage  from '../components/LandingPage';
import  Footer  from '../components/Footer';


const Home = () => {
  return (
    <div>
      <LandingPage/>
      <Footer/>
    </div>
  )
}
export default Home