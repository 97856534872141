import React, { useState, useRef, useEffect } from 'react'
import './css/ProductsAccordion.css';
import { useLocation } from 'react-router-dom';

import stainlessSteel from "../assets/images/Products2/Stainless-Steel.png";
import CarbonSteel from "../assets/images/Products2/Carbon-Steel.png";
import CoatedProducts from "../assets/images/Products2/Coated-Products.png";
import AlloySteel from "../assets/images/Products2/Alloy-Steel.png";
import RedMetals from "../assets/images/Products2/Red-Metals.png";
import Aluminium from "../assets/images/Products2/Aluminuim.png";
import HSLA from "../assets/images/Products2/HSLA.png";
import HRPO from "../assets/images/Products2/HRPO.png";

import DownArrow from "../assets/svg/down-arrow.svg";
import { ReactComponent as MyIcon } from '../assets/svg/down-arrow.svg';

import MetalSheets from "../assets/images/Products-Metal-Sheets.png";

const ProductsAccordion = () => {
  const location = useLocation()
  const firstOpenedAccordion = location.state && location.state.openedAccoirdion
  ? location.state.openedAccoirdion
  : 'topId';

  const [openedAccoirdion, SetOpenedAccoirdion] = useState(firstOpenedAccordion);
  const [pickedScrollId, setPickedScrollID] = useState(firstOpenedAccordion);
  const accordionOrder = ["stainless", "carbon", "coated", "alloy", "red", "aluminium", "hsla", "hrpo"];
  const [fisrtTimeScrolling, setFisrtTimeScrolling] = useState(true);

  function accordionHandler(pickedAccordion){
    let scrollAmmount = 0;
    if(pickedAccordion === openedAccoirdion){
      SetOpenedAccoirdion("none");
    }else{
      if(openedAccoirdion !== 'none'){
        const navbarheight = document.getElementById('navbar-height-dark').offsetHeight;
        let beforeLastPickedAcc = document.getElementById(openedAccoirdion).nextElementSibling;
        let lastPickedAccordionPosition = beforeLastPickedAcc.offsetHeight;
        if(fisrtTimeScrolling){
          lastPickedAccordionPosition = 0;
          setFisrtTimeScrolling(false);
        }

        SetOpenedAccoirdion(pickedAccordion);
        let newPickedAccordionPosition = document.getElementById(pickedAccordion).getBoundingClientRect().top + window.pageYOffset;
        let orderLastOpened = 0;
        let orderToBeOpened = 0;
        for(let i=0; i<8; i++){
          if(openedAccoirdion === accordionOrder[i]) orderLastOpened = i;
          if(pickedAccordion === accordionOrder[i]) orderToBeOpened = i;
        }
        if(orderLastOpened < orderToBeOpened){
          scrollAmmount = newPickedAccordionPosition - lastPickedAccordionPosition - 10 - navbarheight;
        }else{
          scrollAmmount = newPickedAccordionPosition - navbarheight;
        }
        // console.log("[scrollAmmount:" + scrollAmmount + "] [newPickedAcc:" + newPickedAccordionPosition + "] [lastPickedAcc:" + lastPickedAccordionPosition + "]");
        window.scrollTo({
          top : scrollAmmount,
          behavior : 'smooth',
        });
      }else{
        SetOpenedAccoirdion(pickedAccordion);
      }
    }
  }


  useEffect(() => {
    const navbarheight = document.getElementById('navbar-height-dark').offsetHeight;
    let ref = document.getElementById(firstOpenedAccordion).offsetTop;
    let scrollAmount = ref - navbarheight;
    if(scrollAmount <= 0){
      setFisrtTimeScrolling(true);
    }else{
      setFisrtTimeScrolling(false);
    }
    console.log(scrollAmount);
    window.scrollTo({
      top : scrollAmount,
      behavior : 'instant',
    });
  }, [firstOpenedAccordion]);


  return (
    <>
    <span id='topId'></span>
    <div className="accordion-container-background">
      <div className="accordion-container">
        <div className="products-start-container">
          <div className='products-start-texts'>
            <h3>PRODUCTS</h3>
            <h1>Products that deliver exceptional results</h1>
            <p>Welcome to our metal products page, where you'll find a wide variety of top-quality metals to meet your needs. Whether you're in need of alloy steel, aluminum, red metals, stainless steel, or any other metal, we've got you covered.</p>
            <p>Our products are designed to be durable and reliable, so you can rest assured that your project will be completed to the highest standards. </p>
            <p>Browse our selection today and discover the perfect metal for your next project.</p>
          </div>
          <div className='metal-sheets-container'>
            <img src={MetalSheets} alt="MetalSheets" />
          </div>
        </div>
        <h1 className='main-heading'>Our products:</h1>

        <button id='stainless' className={`${openedAccoirdion==='stainless' ? "picked-accordion" : "unpicked-accordion" } `} onClick={() => accordionHandler('stainless')} >
          <div className="accordion-button-container">
            <img src={stainlessSteel} alt="" className='product-image'/>
            <div className='accordion-heading'>
              <p className='accordion-name'>Stainless steel and heat resistant steel</p>
              <p className='read-more'>Read more</p>
            </div>
            <div className='arrow-container'>
              <MyIcon className='arrow-test'/>
            </div>
          </div>
        </button>
        <div className={`panel ${openedAccoirdion==='stainless' ? "expandAccordion" : "" }`}>
          <div className="stainless-container">
            <p className='text-description all-but-tables'>We supply Stainless steel in all tempers from annealed to full hard. We specialize in tight tolerance light gauge applications, we can slit edge oscillate wound and precision cut to length stainless steel in thicknesses through 3/16 (0.1875”).  We have the ability to roll or skive round or square edges. Our products meet ASTM standards and are RoHS compliant.</p>
            <p className='text-description text-description-bottom-margin all-but-tables'><b className='text-description-bold remove-r-margin'>We provide all tempers:</b> DQ Annealed, ¼ Hard, ½ Hard, ¾ Hard & Full Hard</p>
            <div className="flex-table">
              <div className='table-name'>
                <h3>All types of SS that we provide with Chemical Composition Limits, Per Cent</h3>
              </div>
              <div className="header-cell-row">
                <div className="cell-header">Type number</div>
                <div className="cell-header">C</div>
                <div className="cell-header">Mn max</div>
                <div className="cell-header">Cr.</div>
                <div className="cell-header">Ni</div>
              </div>
              <div className="cell-row">
                <div className="cell">201</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">7.50</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">3.50/5.50</div>
              </div>
              <div className="cell-row">
                <div className="cell">202</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">10.00</div>
                <div className="cell">17.00/19.00</div>
                <div className="cell">4.00/6.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">301</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">6.00/8.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">302</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">17.00/19.00</div>
                <div className="cell">8.00/10.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">302B</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">17.00/19.00</div>
                <div className="cell">8.00/10.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">303</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">17.00/19.00</div>
                <div className="cell">8.00/10.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">304</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">18.00/20.00</div>
                <div className="cell">8.00/10.50</div>
              </div>
              <div className="cell-row">
                <div className="cell">304L</div>
                <div className="cell">0.03 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">18.00/20.00</div>
                <div className="cell">8.00/10.50</div>
              </div>
              <div className="cell-row">
                <div className="cell">305</div>
                <div className="cell">0.12 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">17.00/19.00</div>
                <div className="cell">10.50/13.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">308</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">19.00/21.00</div>
                <div className="cell">10.00/12.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">309</div>
                <div className="cell">0.20 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">22.00/24.00</div>
                <div className="cell">12.00/15.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">309S</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">22.00/24.00</div>
                <div className="cell">12.00/15.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">310</div>
                <div className="cell">0.25 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">24.00/26.00</div>
                <div className="cell">19.00/22.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">310S</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">24.00/26.00</div>
                <div className="cell">19.00/22.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">314</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">23.00/26.00</div>
                <div className="cell">19.00/22.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">316</div>
                <div className="cell">0.03 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">10.00/14.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">316L</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">10.00/14.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">317</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">18.00/20.00</div>
                <div className="cell">11.00/15.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">321</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">17.00/19.00</div>
                <div className="cell">9.00/12.00</div>
              </div>

              <div className="cell-row">
                <div className="cell">347</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">17.00/19.00</div>
                <div className="cell">9.00/13.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">348</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">2.00</div>
                <div className="cell">17.00/19.00</div>
                <div className="cell">9.00/13.00</div>
              </div>
              <div className="cell-row">
                <div className="cell">403</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">1.00</div>
                <div className="cell">11.50/13.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">405</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">1.00</div>
                <div className="cell">11.50/14.50</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">410</div>
                <div className="cell">0.08 Max.</div>
                <div className="cell">1.00</div>
                <div className="cell">11.50/13.50</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">414</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">1.00</div>
                <div className="cell">11.50/13.50</div>
                <div className="cell">1.25/2.50</div>
              </div>
              <div className="cell-row">
                <div className="cell">416</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">1.25</div>
                <div className="cell">12.00/14.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">416 Se</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">1.25</div>
                <div className="cell">12.00/14.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">420</div>
                <div className="cell">Over 0.15</div>
                <div className="cell">1.00</div>
                <div className="cell">12.00/14.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">430</div>
                <div className="cell">0.12 Max.</div>
                <div className="cell">1.00</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">430F</div>
                <div className="cell">0.12 Max.</div>
                <div className="cell">1.25</div>
                <div className="cell">14.00/18.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">430F Se</div>
                <div className="cell">0.12 Max.</div>
                <div className="cell">1.25</div>
                <div className="cell">14.00/18.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">431</div>
                <div className="cell">0.20 Max.</div>
                <div className="cell">1.00</div>
                <div className="cell">15.00/17.00</div>
                <div className="cell">1.25/2.50</div>
              </div>
              <div className="cell-row">
                <div className="cell">440A</div>
                <div className="cell">0.60/0.75</div>
                <div className="cell">1.00</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">440B</div>
                <div className="cell">0.75/0.95</div>
                <div className="cell">1.00</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">440C</div>
                <div className="cell">0.95/1.20</div>
                <div className="cell">1.00</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">446</div>
                <div className="cell">0.20 Max.</div>
                <div className="cell">1.50</div>
                <div className="cell">23.00/27.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">701</div>
                <div className="cell">Over 0.10</div>
                <div className="cell">1.00</div>
                <div className="cell">4.00/6.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">702</div>
                <div className="cell">0.10 Max.</div>
                <div className="cell">1.00</div>
                <div className="cell">4.00/6.00</div>
                <div className="cell">-</div>
              </div>
              <div className="cell-row">
                <div className="cell">17-7 PH</div>
                <div className="cell">0.09 Max.</div>
                <div className="cell">1.00</div>
                <div className="cell">16.00/18.00</div>
                <div className="cell">6.50/7.75</div>
              </div>

            </div>
            <div className="all-but-tables">
              <div className='stainless-part'>
                <h2>Austenitic Grades</h2>
                <p>Austenitic stainless steel strips and straps have austenite as their primary phase. These alloys contain chromium and nickel with a composition of iron, chromium, and nickel. Austenitic stainless steel strap is not hardenable by heat treatment. The most familiar stainless steel strap & strip is probably Type 304, sometimes called T304 or simply 304. Type 304 surgical stainless steel is an austenitic stainless steel strip and strap contains chromium and nickel.</p>
                <div className="stainless-boxes">
                  <div className="stainless-box">301, 302, 304, 305</div>
                  <div className="stainless-box">316 & 321 Annealed</div>
                  <div className="stainless-box">17-7 Condition A&nbsp;&&nbsp;C</div>
                </div>
              </div>
              <div className='stainless-part'>
                <h2>Martensitic Grades</h2>
                <p>Martensite is a very hard form (crystalline structure) of stainless steel. When viewed in cross-section, the lenticular (lens-shaped) crystal grains appear acicular (needle-shaped). Martensitic stainless steels are not as corrosion-resistant but are extremely strong and tough, as well as highly machinable, and can be hardened by heat treatment. Martensitic stainless steel contains chromium, molybdenum, nickel, and carbon</p>
                <div className="stainless-boxes">
                  <div className="stainless-box">410</div>
                  <div className="stainless-box">420</div>
                  <div className="stainless-box">440A</div>
                </div>
              </div>
              <div className='stainless-part last-part-in-accordion'>
                <h2>Ferritic Grades</h2>
                <p>Ferritic stainless steels generally have better engineering properties than austenitic grades, but have reduced corrosion resistance, due to the lower chromium and nickel content. Most compositions include molybdenum, aluminum or titanium. Common ferritic grades include 18Cr-2Mo, 26Cr-1Mo, 29Cr-4Mo, and 29Cr-4Mo-2Ni.</p>
                <div className="stainless-boxes">
                  <div className="stainless-box">409</div>
                  <div className="stainless-box">430</div>
                  <div className="stainless-box">430LI</div>
                  <div className="stainless-box">446</div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <button id='carbon' className={`${openedAccoirdion==='carbon' ? "picked-accordion" : "unpicked-accordion" } `} onClick={() => accordionHandler('carbon')} >
          <div className="accordion-button-container">
            <img src={CarbonSteel} alt="" className='product-image'/>
            <div className='accordion-heading'>
              <p className='accordion-name'>Carbon Steel</p>
              <p className='read-more'>Read more</p>
            </div>
            <div className='arrow-container'>
              <MyIcon className='arrow-test'/>
            </div>
          </div>
        </button>
        <div className={`panel ${openedAccoirdion==='carbon' ? "expandAccordion" : "" }`}>
          <div className="stainless-container">
            <p className='text-description all-but-tables'>We specialize in high carbon strips in all tempers. We stock all grades of carbon steel. These steel products are available in ribbon wound coil, traverse (oscillate) wound coil and cut to length pieces.</p>
            <p className='text-description text-description-bottom-margin all-but-tables'><b className='text-description-bold remove-r-margin'>We provide all tempers:</b> DQ Annealed, ¼ Hard, ½ Hard, ¾ Hard & Full Hard. Our products meet ASTM standards and are RoHS compliant.</p>
            <div className="carbon-table">
              <div className="carbon-table-row first-carbon-row">
                <p className='carbon-table-cell-left'>Gauges:</p>
                <p className='carbon-table-cell-right'>.030” - .250”</p>
              </div>
              <div className="carbon-table-row">
                <p className='carbon-table-cell-left'>Strip Widths:</p>
                <p className='carbon-table-cell-right'>.375” - 12.5”</p>
              </div>
              <div className="carbon-table-row">
                <p className='carbon-table-cell-left'>Lengths:</p>
                <p className='carbon-table-cell-right'>4" - 20 feet</p>
              </div>
              <div className="carbon-table-row">
                <p className='carbon-table-cell-left'>Length Tolerance:</p>
                <p className='carbon-table-cell-right'>+/- .010”</p>
              </div>
              <div className="carbon-table-row">
                <p className='carbon-table-cell-left'>Edges:</p>
                <p className='carbon-table-cell-right'>Slit edge, Deburred, Square, Round, and Specialty Edges</p>
              </div>
            </div>
            <div className="flex-table last-part-in-accordion">
              <div className='table-name'>
                <h3>Chemical Composition Limits, Per Cent</h3>
              </div>
              <div className="header-cell-row">
                <div className="cell-header">AISI number</div>
                <div className="cell-header">C</div>
                <div className="cell-header">Mn</div>
                <div className="cell-header">P. Max.</div>
                <div className="cell-header">S. Max.</div>
              </div>
              <div className="cell-row">
                <div className="cell">1008</div>
                <div className="cell">.10 Max.</div>
                <div className="cell">.25 - .50</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1010</div>
                <div className="cell">.08 - .13</div>
                <div className="cell">.30 - .60</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1012</div>
                <div className="cell">0.15 Max.</div>
                <div className="cell">.30 - .60</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1015</div>
                <div className="cell">.12 -.18</div>
                <div className="cell">.30 - .60</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1016</div>
                <div className="cell">.12 - .18</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1017</div>
                <div className="cell">.14 -.20</div>
                <div className="cell">.30 - .60</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1018</div>
                <div className="cell">.14 -.20</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1019</div>
                <div className="cell">14 -.20</div>
                <div className="cell">.70 - 1.00</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1020</div>
                <div className="cell">.17 - 23</div>
                <div className="cell">.30 - .60</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1022</div>
                <div className="cell">.17 - .23</div>
                <div className="cell">.70 - 1.00</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1023</div>
                <div className="cell">.19 - .25</div>
                <div className="cell">.30 - .60</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1025</div>
                <div className="cell">.22 -.28</div>
                <div className="cell">.30 - .60</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1030</div>
                <div className="cell">.27 - .34</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1035</div>
                <div className="cell">.31 - .38</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1040</div>
                <div className="cell">.36 -.44</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">316</div>
                <div className="cell">.39 - .47</div>
                <div className="cell">.70 - 1.00</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1045</div>
                <div className="cell">.42 - .50</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1050</div>
                <div className="cell">.47 - .55</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1055</div>
                <div className="cell">.52 - .60</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1060</div>
                <div className="cell">.55 - .66</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1065</div>
                <div className="cell">.59 -.70</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1070</div>
                <div className="cell">.65 -.76</div>
                <div className="cell">.60 - .90</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1074</div>
                <div className="cell">.69 -.80</div>
                <div className="cell">.50 - .80</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1080</div>
                <div className="cell">.74- .88</div>
                <div className="cell">.60 - .56</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1085</div>
                <div className="cell">.80 -.94</div>
                <div className="cell">.70 - 1.00</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>
              <div className="cell-row">
                <div className="cell">1095</div>
                <div className="cell">.90 - 1.04</div>
                <div className="cell">.30 - .50</div>
                <div className="cell">.040</div>
                <div className="cell">.050</div>
              </div>

            </div>

            
          </div>
        </div>



        <button id='coated' className={`${openedAccoirdion==='coated' ? "picked-accordion" : "unpicked-accordion" } `} onClick={() => accordionHandler('coated')} >
          <div className="accordion-button-container">
            <img src={CoatedProducts} alt="" className='product-image'/>
            <div className='accordion-heading'>
              <p className='accordion-name'>Coated products</p>
              <p className='read-more'>Read more</p>
            </div>
            <div className='arrow-container'>
              <MyIcon className='arrow-test'/>
            </div>
          </div>
        </button>
        <div className={`panel ${openedAccoirdion==='coated' ? "expandAccordion" : "" }`}>
          <div className="stainless-container">
            <p className='text-description all-but-tables text-description-bottom-margin'>Coated products: tin plate, hot, tin dip, electro, matte, bright, extra heavy coatings post plate material, generic. Post plated tin is big part of our business, as well as galvanized - zinc coated. Special temper 1/2 galvanized; 1/4 galvanized or whatever temper is needed.</p>
            <div className="flex-table-coated last-part-in-accordion">
              <div className='cell-row row-coated coated-heading-row'>
                {/* <div>
                  <h3 className="cell-row row-coated coated-heading-cell cell"></h3>
                </div>
                <div>
                  <h3 className="cell-row row-coated coated-heading-cell cell"></h3>
                </div>
                <div>
                  <h3 className="cell-row row-coated coated-heading-cell cell"></h3>
                </div>
                <div>
                  <h3 className="cell-row row-coated coated-heading-cell cell"></h3>
                </div> */}
                <div className="coated-heading-cell extra-coated-heading cell-coated">Product</div>
                <div className="coated-heading-cell extra-coated-heading">Product Gauge Range</div>
                <div className="coated-heading-cell extra-coated-heading">Coating Weight / Class</div>
                <div className="coated-heading-cell extra-coated-heading">Tempers</div>


              </div>
              <div className="cell-row row-coated">
                <div className="cell cell-coated">Hot Dipped Galvanized / Galvannealed</div>
                <div className="cell">.010-.250</div>
                <div className="cell">G30 through G90 / A40-A90 Inquire about heavier coating weights</div>
                <div className="cell">DQ<br/>CQ<br/>¼ Hard<br/>½ Hard<br/>Full Hard</div>
              </div>
              <div className="cell-row row-coated">
                <div className="cell">Electro-Galvanized</div>
                <div className="cell">.008-.080</div>
                <div className="cell">Class A: Flash-Intermediate<b/>Class B: Full<b/>Class C: Double</div>
                <div className="cell">DQ<br/>CQ<br/>¼ Hard<br/>½ Hard<br/>Full Hard</div>
              </div>
              <div className="cell-row row-coated">
                <div className="cell">Electro- Tin Plate and Tin Coated</div>
                <div className="cell">.006-.036</div>
                <div className="cell">¼ lb<br/>½ lb<br/>¾ lb<br/>1 lb Base Box</div>
                <div className="cell">T-1<br/>T-2<br/>T-3<br/>T-4<br/>T-5<br/>T-6</div>
              </div>
              <div className="cell-row row-coated">
                <div className="cell">Aluminized</div>
                <div className="cell">.015-.125</div>
                <div className="cell">T 1-25<br/>T 1-40</div>
                <div className="cell">DQ<br/>CQ<br/>¼ Hard<br/>½ Hard<br/>Full Hard</div>
              </div>
            </div>       
          </div>
        </div>



        <button id='alloy' className={`${openedAccoirdion==='alloy' ? "picked-accordion" : "unpicked-accordion" } `} onClick={() => accordionHandler('alloy')} >
          <div className="accordion-button-container">
            <img src={AlloySteel} alt="" className='product-image'/>
            <div className='accordion-heading'>
              <p className='accordion-name'>Alloy steel</p>
              <p className='read-more'>Read more</p>
            </div>
            <div className='arrow-container'>
              <MyIcon className='arrow-test'/>
            </div>
          </div>
        </button>
        <div className={`panel ${openedAccoirdion==='alloy' ? "expandAccordion" : "" }`}>
          <div className="stainless-container">
            <p className='text-description all-but-tables'>Alloy steels are made by combining carbon steel with one or several alloying elements, such as manganese, silicon, nickel, titanium, copper, chromium and aluminum. These metals are added to produce specific properties that are not found in regular carbon steel. The elements are added in varying proportions (or combinations) making the material take on different aspects such as increased hardness, increased corrosion resistance, increased strength, improved formability (ductility); the weldability can also change.</p>
            <p className='text-description all-but-tables text-description-bottom-margin'>The most important and desired changes in alloy steel are:</p>
            <div className='ul-list all-but-tables'>
              <p>• Increased hardenability.</p>
              <p>• Increased corrosion resistance.</p>
              <p>• Retention of hardness and strength.</p>
            </div>
            <p className='text-description all-but-tables text-description-bottom-margin'>The most important and desired changes in alloy steel are:</p>
          </div>
          <div className="grid-table-7">
            <div className='grid-7-row grid-7-heading'>
              <div>
                <h3 className="grid-7-cell grid-7-cell-first style-0">AISI or SAE&nbsp;#</h3>
              </div>
              <div>
                <h3 className="grid-7-cell style-0">C</h3>
              </div>
              <div>
                <h3 className="grid-7-cell style-0">Mn</h3>
              </div>
              <div>
                <h3 className="grid-7-cell style-0">Cr</h3>
              </div>
              <div>
                <h3 className="grid-7-cell style-0">Ni</h3>
              </div>
              <div>
                <h3 className="grid-7-cell style-0">Mo</h3>
              </div>
              <div>
                <h3 className="grid-7-cell grid-7-cell-last style-0">V</h3>
              </div>
            </div>
          </div>       
          <div className="grid-table-7">
            <div className='grid-7-row grid-7-row-grey grid-7-row-first'>
              <div>
                <p className="grid-7-cell grid-7-cell-first style-1">4140</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.38/.43</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.75/1.00</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.80/1.10</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.15/.25</p>
              </div>
              <div>
                <p className="grid-7-cell grid-7-cell-last style-1">/</p>
              </div>
            </div>
          </div> 
          <div className="grid-table-7">
            <div className='grid-7-row grid-7-row-white grid-7-row-middle'>
              <div>
                <p className="grid-7-cell grid-7-cell-first style-1">4140</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.38/.43</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.75/1.00</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.80/1.10</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.15/.25</p>
              </div>
              <div>
                <p className="grid-7-cell grid-7-cell-last style-1">/</p>
              </div>
            </div>
          </div>
          <div className="grid-table-7">
            <div className='grid-7-row grid-7-row-grey grid-7-row-middle'>
              <div>
                <p className="grid-7-cell grid-7-cell-first style-1">4142</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.40/.45</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.75/1.00</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.80/1.10</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.15/.25</p>
              </div>
              <div>
                <p className="grid-7-cell grid-7-cell-last style-1">/</p>
              </div>
            </div>
          </div> 
          <div className="grid-table-7">
            <div className='grid-7-row grid-7-row-white grid-7-row-middle'>
              <div>
                <p className="grid-7-cell grid-7-cell-first style-1">5150</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.48/.53</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.70/.90</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.70/.90</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell grid-7-cell-last style-1">/</p>
              </div>
            </div>
          </div>
          <div className="grid-table-7">
            <div className='grid-7-row grid-7-row-grey grid-7-row-middle'>
              <div>
                <p className="grid-7-cell grid-7-cell-first style-1">5160</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.56/.64</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.75/1.00</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.70/.90</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell grid-7-cell-last style-1">/</p>
              </div>
            </div>
          </div> 
          <div className="grid-table-7">
            <div className='grid-7-row grid-7-row-white grid-7-row-middle'>
              <div>
                <p className="grid-7-cell grid-7-cell-first style-1">52100</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.98/1.10</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.25/.45</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">1.30/1.60</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell grid-7-cell-last style-1">/</p>
              </div>
            </div>
          </div>
          <div className="grid-table-7">
            <div className='grid-7-row grid-7-row-grey grid-7-row-bottom'>
              <div>
                <p className="grid-7-cell grid-7-cell-first style-1">6150</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.48/.53</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.70/.90</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">.80/1.10</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell style-1">/</p>
              </div>
              <div>
                <p className="grid-7-cell grid-7-cell-last style-1">15 Min</p>
              </div>
            </div>
          </div>

          <div className="alloy-table2 last-part-in-accordion">
            <div className="alloy-table2-heading">
              <h3>AISI or SAE #</h3>
            </div>
            <div className="alloy-table2-row alloy-table2-first">
              <div>
                <p>Annealed</p>
              </div>
              <div>
                <p>B85 Max</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle">
              <div>
                <p>As Rolled</p>
              </div>
              <div>
              <p>B90-102</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-last">
              <div>
                <p>Special Hardness Available</p>
              </div>
              <div>
                <p>Per Request</p>
              </div>
            </div>
          </div>


        </div>



        <button id='red' className={`${openedAccoirdion==='red' ? "picked-accordion" : "unpicked-accordion" } `} onClick={() => accordionHandler('red')} >
          <div className="accordion-button-container">
            <img src={RedMetals} alt="" className='product-image'/>
            <div className='accordion-heading'>
              <p className='accordion-name'>Red Metals</p>
              <p className='read-more'>Read more</p>
            </div>
            <div className='arrow-container'>
              <MyIcon className='arrow-test'/>
            </div>
          </div>
        </button>
        <div className={`panel ${openedAccoirdion==='red' ? "expandAccordion" : "" }`}>
          <div className="stainless-container">
            <p className='text-description all-but-tables bolded'><b>Phosphor Bronze & Nickel Silver Strip, Strap & Roll</b></p>
            <p className='text-description all-but-tables text-description-bottom-margin'>We stock specialty metals like Phosphor Bronze and Nickel Silver in light gauges for stampers of electronic, medical, musical, jewelry, automotive parts and other precision applications.</p>
          </div>
          <div className="alloy-table2 red-table-extra-style">
            <div className="alloy-table2-heading">
              <h3>AISI or SAE #</h3>
            </div>
            <div className="alloy-table2-row alloy-table2-first red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>101-0</p>
              </div>
              <div className='red-second-cell'>
                <p>101 SOFT COPPER</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>102-1</p>
              </div>
              <div className='red-second-cell'>
                <p>PFC COPPER 102 1/4 HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>110-0</p>
              </div>
              <div className='red-second-cell'>
                <p>PFC COPPER 102 1/4 HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>110-1</p>
              </div>
              <div className='red-second-cell'>
                <p>ETP COPPER 110 1/4 HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>110-2</p>
              </div>
              <div className='red-second-cell'>
                <p>ETP COPPER 110 1/4 HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>110-3</p>
              </div>
              <div className='red-second-cell'>
                <p>ETP COPPER-3/4 HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>110-4</p>
              </div>
              <div className='red-second-cell'>
                <p> ETP COPPER 110 FULL HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>110-8</p>
              </div>
              <div className='red-second-cell'>
                <p>110 COPPER SPRING TEMPER</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>143-4</p>
              </div>
              <div className='red-second-cell'>
                <p>143 COPPER HARD FOIL</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>194-2</p>
              </div>
              <div className='red-second-cell'>
                <p>194 COPPER 1/2 HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>201-0</p>
              </div>
              <div className='red-second-cell'>
                <p>201 ANNEALED STAINLESS STEEL</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>201-1</p>
              </div>
              <div className='red-second-cell'>
                <p>201 1/4 HARD STAINLESS STEEL</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>230-2</p>
              </div>
              <div className='red-second-cell'>
                <p>230 1/2 HARD BRASS</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>260-0</p>
              </div>
              <div className='red-second-cell'>
                <p>260 CARTRIDGE BRASS SOFT</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>260-1</p>
              </div>
              <div className='red-second-cell'>
                <p>260 CARTRIDGE BRASS 1/4 HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>260-2</p>
              </div>
              <div className='red-second-cell'>
                <p>260 BRASS HALF HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>260-3</p>
              </div>
              <div className='red-second-cell'>
                <p>260 CARTRIDGE BRASS 3/4 HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-middle red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>260-4</p>
              </div>
              <div className='red-second-cell'>
                <p>260 CARTRIDGE BRASS FULL HARD</p>
              </div>
            </div>
            <div className="alloy-table2-row alloy-table2-last red-extry-style red-table-reverse-color">
              <div className='red-first-cell'>
                <p>260-8</p>
              </div>
              <div className='red-second-cell'>
                <p>260 CARTRIDGE BRASS SPRING TEMPER</p>
              </div>
            </div>      
          </div>
          <div className="stainless-container container-to-hide-830">
            <p className='text-description all-but-tables bolded-wrap'><b className='bolded-'>Phosphor bronze</b> is an alloy of copper with 3.5 to 10% of tin and a significant phosphorus content of up to 1%. The phosphorus is added as deoxidizing agent during melting. These alloys are notable for their toughness, strength, low coefficient of friction, and fine grain. Phosphor bronze is used for springs and other applications where resistance to fatigue, wear and chemical corrosion is required. The grades we carry regularly include 505, 510 and 521 from annealed through full hard and up to the spring tempers. Nickel Silver (aka German Silver), despite its name, contains no silver but does have a silver-like shine when polished. All grades contain copper, nickel and zinc, while some formulations additionally include antimony, tin, lead or cadmium. Grades we commonly carry include 762 & 770 in tempers from annealed through full hard and up to the spring tempers as well.</p>
            <p className='text-description all-but-tables text-description-bottom-margin'>We stock specialty metals like Phosphor Bronze and Nickel Silver in light gauges for stampers of electronic, medical, musical, jewelry, automotive parts and other precision applications.</p>
          </div>
          <div className="container-to-show-830">
            <p className='text-description bolded-wrap'><b className='bolded-'>Phosphor bronze</b> is an alloy of copper with 3.5 to 10% of tin and a significant phosphorus content of up to 1%. The phosphorus is added as deoxidizing agent during melting. These alloys are notable for their toughness, strength, low coefficient of friction, and fine grain. Phosphor bronze is used for springs and other applications where resistance to fatigue, wear and chemical corrosion is required. The grades we carry regularly include 505, 510 and 521 from annealed through full hard and up to the spring tempers. Nickel Silver (aka German Silver), despite its name, contains no silver but does have a silver-like shine when polished. All grades contain copper, nickel and zinc, while some formulations additionally include antimony, tin, lead or cadmium. Grades we commonly carry include 762 & 770 in tempers from annealed through full hard and up to the spring tempers as well.</p>
            <p className='text-description text-description-bottom-margin'>We stock specialty metals like Phosphor Bronze and Nickel Silver in light gauges for stampers of electronic, medical, musical, jewelry, automotive parts and other precision applications.</p>
          </div>

        </div>


        <button id='aluminium' className={`${openedAccoirdion==='aluminium' ? "picked-accordion" : "unpicked-accordion" } `} onClick={() => accordionHandler('aluminium')} >
          <div className="accordion-button-container">
            <img src={Aluminium} alt="" className='product-image'/>
            <div className='accordion-heading'>
              <p className='accordion-name'>Aluminuim</p>
              <p className='read-more'>Read more</p>
            </div>
            <div className='arrow-container'>
              <MyIcon className='arrow-test'/>
            </div>
          </div>
        </button>
        <div className={`panel ${openedAccoirdion==='aluminium' ? "expandAccordion" : "" }`}>
          <div className="stainless-container">
            <b className='text-description-bold aluminium-margins al-top-margin all-but-tables'>We are adding more Aluminum strip grades all the time. Typical Grades are 1100, 3003, 5052.</b>
            <p className='text-description all-but-tables aluminium-margins text-description-bottom-margin al-top-margin'>These are available in the following tempers:</p>
            <div className="stainless-part al-top-margin">
              <div className="aluminium-boxes right-margin-fix">
                <div className="aluminium-box">H32</div>
                <div className="aluminium-box">H34</div>
                <div className="aluminium-box">H36</div>
                <div className="aluminium-box">H38</div>
              </div>
            </div>
            <p className='text-description all-but-tables text-description al-top-margin'>Tandem Metals Aluminum strip customers use Aluminum Strip Painted products to stamp hardware, military, electrical, automotive, house ware and decorative parts among various additional products. Strip, Wide</p>
            <b className='text-description-bold all-but-tables al-top-margin'>Available Aluminum Strip Alloys:</b>
            
          </div>
          <div className="stainless-part al-top-margin">
              <div className="aluminium-boxes aluminium-boxes-2">
                <div className="aluminium-box aluminium-box-2">1050</div>
                <div className="aluminium-box aluminium-box-2">1100</div>
                <div className="aluminium-box aluminium-box-2">1145</div>
                <div className="aluminium-box aluminium-box-2">1200</div>
                <div className="aluminium-box aluminium-box-2">3002</div>
                <div className="aluminium-box aluminium-box-2">3003</div>
                <div className="aluminium-box aluminium-box-2">3105</div>
                <div className="aluminium-box aluminium-box-2">5005</div>
                <div className="aluminium-box aluminium-box-2">5082</div>
                <div className="aluminium-box aluminium-box-2">5086</div>
                <div className="aluminium-box aluminium-box-2">6061</div>
                <div className="aluminium-box aluminium-box-2">6063</div>
                <div className="aluminium-box aluminium-box-2">7075</div>
              </div>
            </div>
            <div className="aluminium-text-containers left-margin-10">
              <p className='text-description bolded-wrap'><b className='bolded-'>Available Aluminum Strip Tempers:</b> 0 to H18/H28/H38/T8</p>
            </div>
            <div className="aluminium-text-containers left-margin-10">
              <p className='text-description bolded-wrap'><b className='bolded-'>Available Aluminum Strip Gauges:</b> .008" to .250"</p>
            </div>
            <div className="aluminium-text-containers left-margin-10">
              <p className='text-description bolded-wrap'><b className='bolded-'>Available Aluminum Strip Gauges:</b> Full 12" to 60" mill widths or custom slit from .090" and above at a tolerance of (+/-.005). Camber maximum .250" in any 60" portion</p>
            </div>
            <div className="aluminium-text-containers left-margin-10 last-part-in-accordion">
              <p className='text-description bolded-wrap'><b className='bolded-'>Available Aluminum Strip Surface Conditions:</b> Painted/Mill/ Satin/ Bright/Kynar.</p>
            </div>
            


        </div>
        



        <button id='hsla' className={`${openedAccoirdion==='hsla' ? "picked-accordion" : "unpicked-accordion" } `} onClick={() => accordionHandler('hsla')} >
          <div className="accordion-button-container">
            <img src={HSLA} alt="" className='product-image'/>
            <div className='accordion-heading'>
              <p className='accordion-name'>HSLA</p>
              <p className='read-more'>Read more</p>
            </div>
            <div className='arrow-container'>
              <MyIcon className='arrow-test'/>
            </div>
          </div>
        </button>
        <div className={`panel ${openedAccoirdion==='hsla' ? "expandAccordion" : "" }`}>
          <div className="stainless-container">
            <b className='text-description-bold aluminium-margins al-top-margin all-but-tables'>High Strength Low Alloy Steel Strip, Strap & Roll</b>
            <p className='text-description all-but-tables aluminium-margins text-description-bottom-margin al-top-margin'>Those steel alloys known as high-strength low-alloy (HSLA) steels provide increased strength-to-weight ratios over conventional low-carbon steels for only a modest price premium. Our rigorous in-house testing and regular outside lab verification insures consistent quality in these designer specific grades.</p>
            <b className='text-description-bold aluminium-margins al-top-margin all-but-tables'>Common HSLA Grades Stocked:</b>
            <div className="stainless-part al-top-margin">
              <div className="aluminium-boxes right-margin-fix">
                <div className="aluminium-box">HSLA GR45</div>
                <div className="aluminium-box">HSLA GR50</div>
                <div className="aluminium-box">HSLA GR60</div>
                <div className="aluminium-box">HSLA GR70</div>
                <div className="aluminium-box">HSLA GR80</div>
              </div>
            </div>
            <div className="carbon-table al-top-margin last-part-in-accordion">
              <div className="carbon-table-row first-carbon-row">
                <p className='carbon-table-cell-left'>Gauges:</p>
                <p className='carbon-table-cell-right'>.001” - .312”</p>
              </div>
              <div className="carbon-table-row">
                <p className='carbon-table-cell-left'>Strip Widths:</p>
                <p className='carbon-table-cell-right'>.090” - 36"</p>
              </div>
              <div className="carbon-table-row">
                <p className='carbon-table-cell-left'>Edges:</p>
                <p className='carbon-table-cell-right'>Deburred, Square, Round, and Specialty Edges available</p>
              </div>
            </div>
          </div>
        </div>




        <button id='hrpo' className={`${openedAccoirdion==='hrpo' ? "picked-accordion" : "unpicked-accordion" } `} onClick={() => accordionHandler('hrpo')} >
          <div className="accordion-button-container">
            <img src={HRPO} alt="" className='product-image'/>
            <div className='accordion-heading'>
              <p className='accordion-name'>HRPO</p>
              <p className='read-more'>Read more</p>
            </div>
            <div className='arrow-container'>
              <MyIcon className='arrow-test'/>
            </div>
          </div>
        </button>
        <div className={`panel ${openedAccoirdion==='hrpo' ? "expandAccordion" : "" }`}>
          <div className="stainless-container">
            <p className='text-description all-but-tables text-description-bottom-margin al-top-margin'>Our stocks of HRPO and one pass hot rolled are found in all grades from 100 IF to HSLA grade 80. We can one pass temper roll all these for gage control and surface appearance.</p>
            <div className='bold-fix'>
              <p className='bold-fix-text-not-bold'>We regularly stock all thicknesses from </p><b className='bold-fix-text'>.050 to .275.</b>
            </div>
            <div className='bold-fix'>
              <p className='bold-fix-text-not-bold'>We slit and edge these products </p><b className='bold-fix-text'>as narrow as 3/8.</b>
            </div>
            <div className='bold-fix'>
              <b className='bold-fix-text'>Widths from .375” to 1.5” </b><p className='bold-fix-text-not-bold'>can be traverse (oscillate) wound.</p>
            </div>
            <div className='bold-fix'>
              <p className='bold-fix-text-not-bold'>Cut Lengths between 2 </p>
              <b className='bold-fix-text'>and 240 inches </b>
              <p className='bold-fix-text-not-bold'>long and </p>
              <b className='bold-fix-text'>.375 to 8 inches </b>
              <p className='bold-fix-text-not-bold'>wide with </p>
              <b className='bold-fix-text'>.010” length </b>
              <p className='bold-fix-text-not-bold'>tolerance</p>
            </div>
            <div className='bold-fix last-part-in-accordion'>
              <p className='bold-fix-text-not-bold'>Deburred, Square, Round, and Specialty Edges available</p>
            </div>
          </div>
        </div>




      </div>
    </div>
    </>
  )
}

export default ProductsAccordion