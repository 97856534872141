import React from 'react';
import ProductsAccordion from '../components/ProductsAccordion';
import Footer from '../components/Footer';
import NavbarDark from '../components/NavbarDark';

const Products = () => {
  return (
    <>
      <NavbarDark />
      <ProductsAccordion />
      <Footer />
    </>
  )
}

export default Products